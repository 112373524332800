import React, { useState } from "react";
import {
  deleteBill,
  existBill,
  getUploadedBills,
} from "../../redux/apiCalls/dataCall";
import { useDispatch, useSelector } from "react-redux";
import { createRoot } from "react-dom/client";
import PopupContent from "./PopupContent";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/alert";
import FilterOptions from "./FilterOptions";
import "./LineItemText.css";

const ListBills = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const companies = useSelector((state) => state.companies.companies);
  const data = useSelector((state) => state.data);
  const navigate = useNavigate();
  let newWindow;
  const [filterOptions, setFilterOptions] = useState({});
  const [firstOpen, setFirstOpen] = useState(true); 

  const abrirPrimeraVez = async () => {
    setFirstOpen(false);
    if (filterOptions.startDate && filterOptions.endDate) {
      
      getUploadedBills(dispatch, user?.accessToken, filterOptions).then((bills) => {
        abrirVentanaEmergente(bills);
      });
    } else {
      Toast.fire({
        icon: "warning",
        title: "Seleccione ambas fechas para filtrar las facturas.",
      });
    }
  };

  const handleClick = (id, e) => {
    e.preventDefault();
    navigate(`/editBill/${id}`);
  };

  async function handleDelete(e, id) {
    e.preventDefault();
  
    Swal.fire({
      title: "¿Está seguro de que desea eliminar esta factura? Se eliminarán todos los registros relacionados.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar factura.",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (newWindow) {
            newWindow.close();
          }
  
          deleteBill(dispatch, id, user?.accessToken).then((res) => {
            if (res?.error) {
              Toast.fire({
                icon: "error",
                title: res?.message,
              });
            }
            getUploadedBills(dispatch, user?.accessToken, filterOptions).then((res) => {
              abrirVentanaEmergente(res);
            });
          });
        } catch (error) {
          Toast.fire({
            icon: "error",
            title: "No se pudo eliminar la factura.",
          });
        }
      }
    });
  }

  async function handleSearch(id) {
    try {
      if (newWindow) {
        newWindow.close();
      }

      if(id !== ""){
        existBill(dispatch, id, "listBills", user?.accessToken).then((res) => {
          if (res?.error) {
            Toast.fire({
              icon: "error",
              title: res?.message,
            });
          } else {
            abrirVentanaEmergente(res);
          }
        });
      }else{
        abrirVentanaEmergente(data?.uploadedBills);
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "No se pudo encontrar la factura.",
      });
    }
  }
  
  const abrirVentanaEmergente = (bills) => {
    const opcionesVentana = "width=800,height=500,scrollbars=yes";
    newWindow = window.open("", "_blank", opcionesVentana);
  
    if (newWindow) {
      setTimeout(() => {
        const contenedor = newWindow.document.createElement("div");
        newWindow.document.body.appendChild(contenedor);
  
        createRoot(contenedor).render(
          <PopupContent
            bills={bills}
            filterOptions={filterOptions}
            user={user}
            handleClick={handleClick}
            handleDelete={handleDelete}
            companies={companies}
            handleSearch={handleSearch} 
            firstOpen={firstOpen}
            setFirstOpen={setFirstOpen}
          />
        );
      }, 300); 
    } else {
      console.error("No se pudo abrir la ventana emergente.");
    }
  };
  
  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
    >
      <FilterOptions
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        setFirstOpen={setFirstOpen}
      />
      <button
        style={{ marginLeft: "10px" }}
        onClick={() => abrirPrimeraVez()}
      >
        Ver últimas facturas
      </button>
    </div>
  );
};

export default ListBills;
