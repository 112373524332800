import React from "react";
import domtoimage from "dom-to-image";
import './Report.css';
import { FaFileDownload } from "react-icons/fa";
import { provinces } from "../../helpers/infoFilter";
import jsPDF from 'jspdf';

let nameProvinces;

const ReportEnegiaPdf = ({ filterOptions }) => {
  nameProvinces = filterOptions.provinces.map(value => {
    const province = provinces.find(p => p.value === value);
    return province ? province.name : value;
  }).join(", ");

  const captureDivAsImage = async (divId) => {
    const divToCapture = document.getElementById(divId);
    // Ocultar el elemento que deseas ocultar
    const elementToHide = document.getElementById('div6-corpSum');
    if (elementToHide) {
      elementToHide.style.display = 'none';
    }

    const imageDataUrl = await domtoimage.toPng(divToCapture);

    // Restaurar el elemento después de la captura
    if (elementToHide) {
      elementToHide.style.display = '';
    }

    return imageDataUrl;
  };

  const handlePdfDownload = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    let yCoordinate = 10;

    pdf.text('ENERGÍA', 10, yCoordinate);
    yCoordinate += 10;

    const additionalTextLines = [
      filterOptions.startDate ? `Fechas: ${filterOptions.startDate} - ${filterOptions.endDate}` : '',
      filterOptions.provinces.length > 0 ? `Provincias: ${nameProvinces}` : '',
      filterOptions.companies.length > 0 ? `Sucursales: ${filterOptions.companies.join(', ')}` : '',
      filterOptions.utilities.length > 0 ? `Servicio: ${filterOptions.utilities.join(', ')}` : ''
    ];

    additionalTextLines.forEach(line => {
      if (line) {
        pdf.setFontSize(8);
        pdf.text(line, 12, yCoordinate);
        yCoordinate += 5; // Ajustar coordenada y para cada línea
      }
    });

    const divsToCapture = [
      "divDashConsumption",
    ];

    await Promise.all(divsToCapture.map(async (divId) => {
      const imageDataUrl = await captureDivAsImage(divId);
      pdf.addImage(imageDataUrl, 'PNG', 10, yCoordinate, 190, 0);
      yCoordinate += 190; // Ajustar coordenada y para la imagen
    }));

    pdf.save("report_energia.pdf");
  };

  return (
    <b onClick={handlePdfDownload}>
      <FaFileDownload />
      Descargar PDF
    </b>
  );
};

export default ReportEnegiaPdf;
