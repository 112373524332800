import { utils, writeFile } from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { FaFileDownload } from "react-icons/fa";
import { Toast } from "../../../helpers/alert";
import { getBillsReport } from "../../../redux/apiCalls/billsReportCall";

export default function AuditReport({ filters }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.currentUser);

  const downloadExcel = () => {
    Toast.fire({
      icon: "info",
      title: "Se está generando el reporte. Pronto comenzará la descarga.",
    });
    getBillsReport(dispatch, user?.accessToken, filters)
      .then((res) => {
        if (res?.totalInvoices && Array.isArray(res?.totalInvoices)) {
          const wb = utils.book_new();
          const headerDates = [];

          // Agregar "Fechas de filtrado" al principio de headerDates
          headerDates.unshift(
            `AUDITORÍA FACTURACIÓN POR MES - AÑO ${filters?.year}`
          );

          const headerColumns = [
            "SUCURSAL",
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
            "TOTAL GENERAL POR SUCURSAL",
          ];

          const ws = utils.aoa_to_sheet([
            ["", "", ""].concat(headerDates),
            [],
            headerColumns,
          ]);

          ws["!cols"] = [
            { width: 30 },
            ...Array(headerColumns.length - 1).fill({ width: 10 }),
          ];

          let totalGeneralColumn = new Array(headerColumns.length - 2).fill(0); // Se excluyen la columna de "SUCURSAL" y "TOTAL GENERAL 1"
          let rowIndex = 3; // Empieza después de los encabezados

          const aggregatedData = {};

          res.totalInvoices.forEach((monthData, monthIndex) => {
            const month = Object.keys(monthData)[0];
            const data = monthData[month];

            if (Array.isArray(data)) {
              data.forEach((item) => {
                const site = item["nombre del sitio"];
                if (!aggregatedData[site]) {
                  aggregatedData[site] = new Array(12).fill(0);
                }
                aggregatedData[site][monthIndex] = item.act || 0;
              });
            }
          });

          Object.keys(aggregatedData).forEach((site) => {
            const numericValues = aggregatedData[site];
            const totalGeneral1 = numericValues.reduce(
              (acc, value) => acc + value,
              0
            );

            const row = [site, ...numericValues, totalGeneral1];
            utils.sheet_add_aoa(ws, [row], { origin: { r: rowIndex++, c: 0 } });

            numericValues.forEach((value, colIndex) => {
              totalGeneralColumn[colIndex] += value;
            });
          });

          const totalGeneral2 = [
            "TOTAL GENERAL POR MES",
            ...totalGeneralColumn,
            totalGeneralColumn.reduce((acc, value) => acc + value, 0),
          ];

          utils.sheet_add_aoa(ws, [totalGeneral2], {
            origin: { r: rowIndex, c: 0 },
          });

          utils.book_append_sheet(wb, ws, "Reporte auditoria por mes");
          writeFile(
            wb,
            `ReporteAuditoria_Proveedor${filters?.supplierSelected}-Año${filters?.year}.xlsx`
          );
        }
      })
      .catch((err) => {
        Toast.fire({
          icon: "error",
          title: `Hubo un error al intentar descargar el reporte. Intentelo nuevamente o pruebe otras opciones. ${err}`,
        });
      });
  };

  return (
    <>
      <button onClick={downloadExcel}>
        <FaFileDownload /> Descargar facturación por mes
      </button>
    </>
  );
}
