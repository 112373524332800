import React, { useEffect, useState } from "react";
import DonutChart from "../../Charts/DonutChart";
import BulletChart from "../../Charts/BulletChart";
import BarChartAgua from "../../Charts/BarChartAgua";
import MapsContainer from "../../Charts/MapsContainer/MapsContainer";

const DashGrid = ({ data, waterConsumption, filterOptions, generalDataCO2 }) => {
  const [totalCO2, setTotalCO2] = useState(0);


  useEffect(() => {
    if (waterConsumption && waterConsumption.length > 0) {
      const filteredWaterConsumption = waterConsumption.filter(office => office.CO2 !== -1);
      let totalCO2 = filteredWaterConsumption.reduce((acc, office) => acc + office.CO2, 0);
      totalCO2 = totalCO2.toFixed(2);
      setTotalCO2(totalCO2);

    } else {
      setTotalCO2(0);
    }
  }, [waterConsumption, data, filterOptions]);


  return (
    <div className="parent-sustDash">
      <div className="div1-sustDash panel firstWidget" id="divSostentabilidad" >
        <h4 className="text-value">{totalCO2.toLocaleString()} Ton CO2</h4>
        <label className="text-title">Emisión Total CO2</label>
      </div>
      <div className="div2-sustDash" id="divSostentabilidad1">
        <DonutChart data={waterConsumption} type={"Water"} />
      </div>
      <div className="div3-sustDash" id="divSostentabilidad2">
        <BarChartAgua generalDataCO2={generalDataCO2}/>
      </div>
      <div className="div4-sustDash" id="div4-sustDash">
        <MapsContainer CO2={waterConsumption} />
      </div>
      <div className="div5-sustDash"  id="divSostentabilidad3">
        <BulletChart data={waterConsumption} type={"CO2Cons"}/>
        <BulletChart data={waterConsumption} type={"waterCons"} />
      </div>
      {/* <div className="div8-sustDash" style={{width: "200%"}} id="div8-sustDash">
        <MapWaterChart waterConsumption={waterConsumption}/>
      </div> */}
      {/* <div className="div7-sustDash" id="divSostentabilidad4">
      </div> */}
    </div>
  );
};

export default DashGrid;
