import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";

const TableVendors = ({ type, invoice }) => {
  const { theme } = useContext(ThemeContext);
  const supplierTypes = new Set();
  let idCounter = 1;

  // Filtrar y calcular los costos totales por proveedor
  invoice?.forEach((item) => {
    if (!supplierTypes.has(item?.supplier?.trim().split(" ")[0].toUpperCase())) {
      supplierTypes.add(item?.supplier?.trim().split(" ")[0].toUpperCase());
    }
  });

  const totalCostsBySupplier = Array.from(supplierTypes).reduce(
    (acc, supplier) => {
      let unit = ""
      const filteredTotal = invoice?.filter(
        (item) => {
          const value = type === "consumpVendors" ? parseFloat(item?.consumption) : item?.cost;
          return value !== null &&
          !isNaN(parseFloat(value)) &&
          isFinite(value) &&
          value !== " " &&
          item.lineItemText !== "Total Factura" &&
          item?.supplier?.trim().split(" ")[0].toUpperCase() === supplier && 
          ((type !== "consumpVendors") || (type === "consumpVendors" && item?.units !== "N/A" && item?.units !== "AR"))
        }
      );

      const totalCost = filteredTotal?.reduce(
        (acc, item) => {
          const value = type === "consumpVendors" ? item?.consumption : item?.cost;
          acc += parseFloat(value);
          unit = item?.units
          return acc;
        },
        0
      );
      const formattedTotalCost = totalCost?.toLocaleString("es-AR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      acc.push({ supplier, cost: formattedTotalCost, units:unit });
      return acc;
    },
    []
  );

  // Ordenar los datos por proveedor
  totalCostsBySupplier?.sort((a, b) => {
    const valueA = parseFloat(a.cost.replace(/\./g, "").replace(",", "."));
    const valueB = parseFloat(b.cost.replace(/\./g, "").replace(",", "."));
    return valueB - valueA;
  });

  return (
    <div style={{ overflowX: "auto" }}>
      <div
        style={{ maxHeight: "250px", overflowY: "auto", position: "relative" }}
      >
        <table
          className={`table ${
            theme === "dark" ? "table-striped" : "table-dark table-striped"
          }`}
        >
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Nombre del proveedor</th>
              <th scope="col">
                {type === "consumpVendors" ? "Consumo" : "Gasto Proveedor"}
                <i className="sort-arrow">
                  {/* Aquí puedes incluir la lógica para el ordenamiento, si es necesario */}
                  {/* <FaAngleUp /> */}
                  {/* <FaAngleDown /> */}
                </i>
              </th>
            </tr>
          </thead>
          <tbody>
          {totalCostsBySupplier?.map(({ supplier, cost, units }, index) => (
            // Filtrar las filas donde el proveedor no sea undefined
            supplier !== undefined && (
              <tr key={index}>
                <th scope="row">{idCounter++}</th>
                <td>{supplier}</td>
                <td>{type === "consumpVendors" ? `${cost} ${units}` : `AR$ ${cost}`}</td>
              </tr>
            )
          ))}
        </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableVendors;
