import React, { useState } from "react";
import { mappingItems, conceptosOtrosImpuestos, conceptosOtrosRecargos, waterAccounts } from "../../helpers/data";
import { getPdfBill } from "../../redux/apiCalls/uploadBillCall";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../helpers/alert";
import { FaRedoAlt } from "react-icons/fa";

const Uploaded = ({
  user,
  onDataExtracted,
  item,
  account,
  setBillIdChecked,
  setBillIdExists,
}) => {
  const pdfBill = useSelector((state) => state.pdfBill.uploadBill);
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [extractedData, setExtractedData] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setExtractedData(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    if (!selectedFile) {
      console.error("No hay archivo seleccionado");
      setIsLoading(false);
      return;
    }
  
    const formData = new FormData();
    formData.append("file", selectedFile);
  
    try {
      const response = await getPdfBill(dispatch, formData, user?.accessToken);
  
      if (response?.error) {
        Toast.fire({
          icon: "error",
          title: "Hubo un error al intentar obtener datos de la factura.",
        });
        setIsLoading(false);
      } else {
        const result = response.data;
        const newMatchedItems = processMatching(result?.lineItems);
  
        if (onDataExtracted) {
          setBillIdChecked(false);
          setBillIdExists(false);
  
          const bill_ID = selectedFile?.name?.replace(/\.pdf$/i, "");
          const infoBill = {
            bill_ID: bill_ID,
            dueDate: result?.due_date,
            invoiceDate: result?.invoice_date,
          };
  
          onDataExtracted(newMatchedItems, infoBill);
        }
        setIsLoading(false);
        setExtractedData(true)
      }
    } catch (error) {
      console.error("Error al subir el archivo:", error);
      Toast.fire({
        icon: "error",
        title: "Error al subir el archivo o procesar la factura.",
      });
      setIsLoading(false);
    }
  };
  

  const processMatching = (lineItems) => {
    const matchedArray = [];
    let selectedMapping = [];
    const nameSupplier = account?.supplierName?.toUpperCase();
    if(nameSupplier?.includes("AYSA")){
      selectedMapping = mappingItems["AYSA"];
    } else{
      selectedMapping = mappingItems[account?.supplierName];
    }

    let subsidioTotal = 0;
    let cargosImpuestos = 0;
    let otrosRecargos = 0;
    let tramoCosto = 0;
    let tramoCons = 0;
    let hasSubsidio = false;

    // Función para normalizar montos
    const normalizeAmount = (amount) => {
      amount = amount?.toString()
      if (amount?.endsWith("-")) {
        let sanitizedAmount = amount.trim().replace(/,/g, "");
        sanitizedAmount = `-${sanitizedAmount.slice(0, -1)}`;
        return sanitizedAmount;
      } else if (/\s/.test(amount) || /,/.test(amount)) {
        
        // Reemplaza espacios entre la coma decimal y los decimales
        let sanitizedAmount = amount
          .replace(/\.\s*/g, "")    // Elimina el punto si está seguido de espacios
          .replace(/\s*,\s*/g, ".") // Convierte una coma decimal con espacios en punto decimal
          .replace(/,/g, "");       // Remueve todas las demás comas
        return sanitizedAmount;
      }
      return amount;
    };
    

    // Iteramos sobre los conceptos de la factura
    lineItems.forEach((lineItem) => {
      const invoiceDesc = lineItem.description?.toLowerCase();
      const normalizedAmount = normalizeAmount(lineItem.amount);
      const quantity = parseFloat(normalizeAmount(lineItem?.quantity));  //  convertir a número

      let matchedConcept;

      // Lógica específica para coincidencias parciales de Edesur y Edenor
      if (
        account.supplierName === "Edesur T1" ||
        account.supplierName === "Edesur T2" ||
        account.supplierName === "Edesur T3" ||
        account.supplierName === "Edenor T2" ||
        account.supplierName === "EDEN T2 B1" ||
        account.supplierName === "EPE_Santa_Fe T2  2 B1" ||
        account.supplierName === "EPE_Santa_Fe T2 2A11" ||
        account.supplierName === "EDELAP T2B11 BT" || 
        account.supplierName === "EDEMSA T2 Especial" ||
        account.supplierName === "EDEMSA T2 R BT" ||
        account.supplierName === "EDEA T2 BT" || 
        account.supplierName === "EcoGas P1" || 
        account.supplierName === "Metrogas P1PR" ||
        account.supplierName === "Metrogas P1CA" ||
        account.supplierName === "METROGAS P2CA" 
      ) {
        matchedConcept = selectedMapping.find((concept) =>
          invoiceDesc.includes(concept.nombreFactura)
        );
      } else {
        matchedConcept = selectedMapping?.find(
          (concept) => invoiceDesc === concept.nombreFactura
        );
      }

      if (matchedConcept) {
        // Caso especial: "Cargo variable - kWh" para Edenor T2

        // Verificamos si el nombreForm coincide con algún 'lineItemText' del array 'item'
        const matchedItem = item.find(
          (i) =>
            i.lineItemText?.trim()?.toLowerCase() ===
            matchedConcept?.nombreForm?.toLowerCase()
        );
        const matchedItemConsump = matchedItem?.consumptionUnit;
        if (matchedItem) {
          // Creamos un nuevo objeto basado en las coincidencias
          const newItem = {
            class: matchedItem.class,
            lineItemText: matchedItem.lineItemText,
            units:
              matchedItemConsump && matchedItemConsump !== "null"
                ? matchedItemConsump?.trim()
                : matchedItem?.costUnit?.trim(),
          };

          //si es edenor extraemos los kwh del concepto
          if (
            account.supplierName === "Edenor T2" &&
            invoiceDesc.includes("cargo variable - kwh")
          ) {
            // Extraemos el valor después de "kWh"
            const kWhValue = lineItem.description.match(/kwh\s(\d+)/i)?.[1]; // Extrae el número
            newItem.quantity = kWhValue;
          }

          //  agregar 'cost' si tiene un valor
          const costValue = matchedConcept.cost
            ? Number(normalizedAmount)
            : null;
          if (!isNaN(costValue) && costValue !== null) {
            newItem.cost = costValue;
          }

          // agregar 'consumption' si tiene un valor numérico
          if (matchedConcept.consump && !isNaN(quantity)) {
            newItem.consumption = quantity;
          }

          // Verificar si ya existe un ítem con el mismo texto
          const existingItemIndex = matchedArray.findIndex(
            (existingItem) => existingItem.lineItemText === newItem.lineItemText
          );

          if (existingItemIndex !== -1) {
            const existingItem = matchedArray[existingItemIndex];
            // Si existe, verificar si el nuevo costo es mayor
            if (
              newItem.cost &&
              (!existingItem.cost || newItem.cost > existingItem.cost)
            ) {
              // Mover el costo anterior a otros recargos antes de reemplazarlo
              cargosImpuestos += existingItem.cost || 0;

              // Reemplazar el ítem con el nuevo costo mayor
              matchedArray[existingItemIndex] = {
                ...existingItem,
                cost: newItem.cost, // Mantener el mayor costo
                consumption: existingItem.consumption ?? newItem.consumption, // Mantener el consumo existente o asignar el nuevo
              };
            } else {
              // Si el nuevo valor es menor, sumarlo a "cargosImpuestos"
              if (newItem.cost < existingItem.cost) {
                cargosImpuestos += newItem.cost || 0;
              }
              matchedArray[existingItemIndex] = {
                ...existingItem,
                consumption: existingItem.consumption ?? newItem.consumption, // Mantener el consumo existente o asignar el nuevo
              };
            }
          } else {
            matchedArray.push(newItem);
          }
        }
      } else {
        // Si no hay coincidencia en el mapping, sumamos el costo al total de otros recargos
        if (
          conceptosOtrosImpuestos[account?.supplierName]?.includes(invoiceDesc)
        ) {
          cargosImpuestos += Number(normalizedAmount) || 0;
        }

        if (
          conceptosOtrosRecargos[account?.supplierName]?.includes(invoiceDesc)
        ) {
          otrosRecargos += Number(normalizedAmount) || 0;
        }

        if(invoiceDesc === "recargo" || (invoiceDesc.includes("dif. de balance-tasa")) ||(account?.supplierName === "EDEMSA T2 Especial" && invoiceDesc.includes("recargos por mora"))){
          otrosRecargos += Number(normalizedAmount);
        }

        if(account.supplierName === "EPE_Santa_Fe T2  2 B1" && (invoiceDesc === "tangente fi" || invoiceDesc === "recargo/bonificación factor de potencia")){
          const existingItemIndex = matchedArray.findIndex(
            (existingItem) => existingItem.lineItemText === "Energia Reactiva T3"
          );
          if(existingItemIndex !== -1){
            const existingItem = matchedArray[existingItemIndex];
            matchedArray[existingItemIndex] = {
              ...existingItem,
              cost: `-${normalizedAmount}`, // Mantener el mayor costo
            };
          } else{
            matchedArray.push({
              class: "Energia Reactiva",
              lineItemText: "Energia Reactiva T3",
              cost: `-${normalizedAmount}`,
              units: "Kvar",
            });
          }
        }

        if (
          invoiceDesc.startsWith("nd") &&
          (account.supplierName === "Edesur T1" ||
            account.supplierName === "Edesur T2" ||
            account.supplierName === "Edesur T3")
        ) {
          otrosRecargos += Number(normalizedAmount) || 0;
        }

        //para ecogas validar concepto que puede variar por cant de cuotas
        if (
          (invoiceDesc.startsWith("dif.bce.com e ind") || invoiceDesc.startsWith("cont. com. e ind"))&& account.supplierName === "EcoGas P1"
        ) {
          cargosImpuestos += Number(normalizedAmount) || 0;
        }

      }

      //si es metrogas extraemos el consumo 
      if (invoiceDesc.includes("consumo tramo 1") && account?.supplierName === "MetroGas P1PR") {
        // Extraemos el valor antes de "m3"
        const match = lineItem.description.match(/tramo\s1\s([\d.,]+)\s*m3/i);
        
        if (match && match[1]) {
          // Normalizamos el número, reemplazando la coma decimal con punto si es necesario
          const normalizedValue = normalizeAmount(match[1])
          matchedArray.push({
            class: "Gas Cons. a 9300 Kcal/m3",
            lineItemText: "Consumo a 9300 Kcal/m3",
            units: "m3",
            consumption: normalizedValue
          });
        }
      }

      //Si es metrogas p2ca, sumamos valores de consumo y costos de tramo 1 y 2
      if(nameSupplier === "METROGAS P2CA" && invoiceDesc.includes("consumo tramo")){
        
        const tramo1Match = lineItem.description.match(/tramo\s1\s([\d.,]+)\s*m3/i);
        const tramo2Match = lineItem.description.match(/tramo\s2\s([\d.,]+)\s*m3/i);
        tramoCosto += Number(normalizedAmount)
        
        if (tramo1Match && tramo1Match[1]) {
          const tramo1Value = Number(normalizeAmount(tramo1Match[1]));
          tramoCons += tramo1Value;
        }
      
        if (tramo2Match && tramo2Match[1]) {
          const tramo2Value = Number(normalizeAmount(tramo2Match[1]));
          tramoCons += tramo2Value;
        }
      
      }

      //Verificar si es Edenor T3 y si no hay 'tangente' usar 'tg fi' siempre y cuando sea menor a 1 (a veces trae el valor de e.reactiva)
      if(account?.supplierName === "Edenor T3" && invoiceDesc === "tg fi" && quantity < 1){
        const tgfiExists = matchedArray.find(i => i.lineItemText === "Tg fi")
        if(!tgfiExists){
          matchedArray.push({
            class: "Tangente fi",
            lineItemText: "Tg fi",
            consumption: quantity,
            units: "N/A",
          });
        }
        
      }

      if(account.supplierName === 'Camuzzi - GENERAL "P"' && invoiceDesc === "ley defensa al consumidor"){
        cargosImpuestos += Number(`-${normalizedAmount}`)
      }

      if(nameSupplier?.includes("AYSA") && invoiceDesc === "adquirida-demandada"){
        const isMensual = waterAccounts.includes(account?.numeroCuenta);
        matchedArray.push({
          class: "Agua Consumida",
          lineItemText: "Consumo",
          consumption: isMensual ? quantity : Number(quantity / 2),
          units: "m3"
        });
      }

      // Verificar si es un concepto de "SUBSIDIO ESTADO NACIONAL"
      if (
        account.supplierName.includes("EDEN") &&
        invoiceDesc.includes("SUBSIDIO ESTADO NACIONAL".toLowerCase())
      ) {
        hasSubsidio = true;
        subsidioTotal += Number(normalizedAmount) || 0;
      }
    });
    

    // Si se encontraron subsidios, agregamos un solo ítem consolidado con el total
    if (hasSubsidio) {
      matchedArray.push({
        class: "Otros",
        lineItemText: "Subsidio Estado Nacional",
        cost: subsidioTotal,
        units: "AR"
      });
    }

    // Si hay otros recargos, los agregamos al matchedArray
    if (typeof cargosImpuestos === 'number' && !isNaN(cargosImpuestos)) {
      matchedArray.push({
        class: "Impuestos",
        lineItemText: "Otros impuestos y tasas",
        cost: cargosImpuestos?.toFixed(2),
        units: "AR",
      });
    }

    if (typeof otrosRecargos === 'number' && !isNaN(otrosRecargos)) {
      matchedArray.push({
        class: "Otros",
        lineItemText: "Otros recargos",
        cost: otrosRecargos?.toFixed(2),
        units: "AR",
      });
    }


    
    // Metrogas- Solo agregar al array si encontramos algún valor
    if (tramoCosto) {
      matchedArray.push({
        class: "Gas Consumido",
        lineItemText: "CONSUMO TRAMO 1",
        units: "AR",
        cost: tramoCosto, // Suma de los valores de tramo 1 y tramo 2
      });
    }

    if (tramoCons) {
      matchedArray.push({
        class: "Gas Cons. a 9300 Kcal/m3",
        lineItemText: "Consumo a 9300 Kcal/m3",
        units: "m3",
        consumption: tramoCons, // Suma de los valores de tramo 1 y tramo 2
      });
    }
    
    return matchedArray; //array con los objetos que coinciden sin duplicados
  };

  const reloadData = (e) => {
    e.preventDefault();
    const newMatchedItems = processMatching(pdfBill?.lineItems);

    const bill_ID = selectedFile?.name?.split(".pdf")[0];
    const infoBill = {
      bill_ID: bill_ID,
      dueDate: pdfBill?.due_date,
      invoiceDate: pdfBill?.invoice_date,
    };

    onDataExtracted(newMatchedItems, infoBill);
  }
  
  return (
    <div>
      <div>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          className="custom-file-input"
        />
        <p>{selectedFile?.name}</p>
      </div>
      {selectedFile?.name && !isLoading && !extractedData ? (
        <button className="btn-pdf" type="button" onClick={handleSubmit}>
          Procesar factura PDF
        </button>
      ) : selectedFile?.name && isLoading ? (
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : null}
       {/* {pdfBill && !isLoading && extractedData ? <button onClick={reloadData}><i><FaRedoAlt/></i></button> : null}  */}
    </div>
  );
};

export default Uploaded;
