import { createSlice } from "@reduxjs/toolkit";

const uploadBillSlice = createSlice({
  name: "uploadBill",
  initialState: {
    uploadBill: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    uploadBillStart: (state) => {
      state.isFetching = true;
    },
    uploadBillSuccess: (state, action) => {
      state.uploadBill = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    uploadBillFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logoutUploadBill: (state) => {
      state.uploadBill = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});

export const {
  uploadBillStart,
  uploadBillSuccess,
  uploadBillFailure,
  logoutUploadBill,
} = uploadBillSlice.actions;

export default uploadBillSlice.reducer;
