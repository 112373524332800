import { publicRequest } from "../../requestMeth"; 
import { billsReportFailure, billsReportStart, billsReportSuccess, powerReportStart, powerReportFailure, powerReportSuccess, energyReportSuccess, energyReportFailure, energyReportStart, } from "../reducers/billsReportReducer";

export const getBillsReport = async (dispatch, token, querys) => {
    dispatch(billsReportStart());
    try {
        const res = await publicRequest.get(`/data/anualEditor?year=${querys?.year}`, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(billsReportSuccess(res.data));
        return res.data;
    } catch (error) {
        dispatch(billsReportFailure({}));
        return error.response.data
    }
};

export const getPowerReport = async (dispatch, token, querys) => {
    dispatch(powerReportStart());
    try {
        // Inicializar la URL base
        let url = '/data/auditPower?';

        // Si hay supplierSelected, usar ese valor, sino, usar "otrosproveedores=true"
        if (querys?.supplierSelected) {
            url += `supplier=${querys?.supplierSelected}`;
        } else {
            url += 'otrosProveedores=true'; // Asumiendo que en tu backend se usa "otrosproveedores=true"
        }

        // Añadir el año al final
        url += `&year=${querys?.year}`; // Año es obligatorio, por lo que siempre lo añadimos al final

        // Hacer la llamada a la API
        const res = await publicRequest.get(url, {
            headers: { token: `Bearer ${token}` }
        });

        dispatch(powerReportSuccess(res.data));
        return res.data;
    } catch (error) {
        dispatch(powerReportFailure({}));
        return error.response?.data || error;
    }
};


export const getEnergyReport = async (dispatch, token, querys) => {
    dispatch(energyReportStart());
    try {
        // Inicializar la URL base
        let url = '/data/auditEnergy?';

        // Si hay supplierSelected, usar ese valor, sino, usar "otrosProveedores=true"
        if (querys?.supplierSelected) {
            url += `supplier=${querys?.supplierSelected}`;
        } else {
            url += 'otrosProveedores=true'; // Asumiendo que en tu backend se usa "otrosProveedores=true"
        }

        // Añadir el año al final
        url += `&year=${querys?.year}`; // Año es obligatorio, por lo que siempre lo añadimos al final

        // Hacer la llamada a la API
        const res = await publicRequest.get(url, {
            headers: { token: `Bearer ${token}` }
        });

        dispatch(energyReportSuccess(res.data));
        return res.data;
    } catch (error) {
        dispatch(energyReportFailure({}));
        return error.response?.data || error;
    }
};
