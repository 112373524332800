import { createSlice } from "@reduxjs/toolkit";

const billEditSlice = createSlice({
  name: "billEdit",
  initialState: {
    editBill: null,
    existBill: null,
    isFetching: false,
    error: false,
    previousDate: null,
    isFetchingPreviousDate: false,
    errorPreviousDate: false
  },
  reducers: {
    editBillStart: (state) => {
      state.isFetching = true;
    },
    editBillSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.editBill = action.payload
    },
    editBillFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    createBillStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    createBillSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.editBill = action.payload
    },
    createBillFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    existBillStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    existBillSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.existBill = action.payload
    },
    existBillFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    previousDateStart: (state) => {
      state.isFetchingPreviousDate = true;
      state.errorPreviousDate = false;
    },
    previousDateSuccess: (state, action) => {
      state.isFetchingPreviousDate = false;
      state.errorPreviousDate = false;
      state.previousDate = action.payload
    },
    previousDateFailure: (state) => {
      state.isFetchingPreviousDate = false;
      state.errorPreviousDate = true;
    },
    logoutEditBill: (state) => {
      state.editBill = null;
      state.existBill = null;
      state.previousDate = null;
      state.isFetching = false;
      state.error = false;
      state.isFetchingPreviousDate = false;
      state.errorPreviousDate = false;
    },
  },
});


export const { editBillStart, editBillSuccess, editBillFailure, createBillFailure, createBillStart, createBillSuccess, logoutEditBill, existBillFailure, existBillStart, existBillSuccess, previousDateStart, previousDateSuccess, previousDateFailure } = billEditSlice.actions;
export default billEditSlice.reducer;