import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getUploadedBills } from "../../redux/apiCalls/dataCall";
import { format, isValid } from "date-fns";

function FilterOptions({ filterOptions, setFilterOptions, setFirstOpen }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // useEffect(() => {
  //   console.log("acacc filterOptions");
    
  //   getUploadedBills(dispatch, user?.accessToken, filterOptions);
  // }, [dispatch, user, filterOptions, startDate, endDate]);

  useEffect(() => {
    // Se ejecuta solo una vez al cargar el componente
    const currentDate = new Date();
    if (isValid(currentDate)) {
      const formattedStartDate = format(currentDate, "yyyy-MM-dd");
      setStartDate(currentDate);
      setEndDate(currentDate);

      // Actualiza el state con las fechas iniciales
      setFilterOptions({
        ...filterOptions,
        startDate: formattedStartDate,
        endDate: formattedStartDate,
      });
    }
  }, [setFilterOptions]);

  const onChange = (dates) => {
    const [start, end] = dates;
    const startFormat = start && isValid(start) ? format(start, "yyyy-MM-dd") : startDate;
    const endDateToSet = end && isValid(end) ? format(end, "yyyy-MM-dd") : startDate;

    setFilterOptions({
      ...filterOptions,
      startDate: startFormat,
      endDate: endDateToSet,
    });

    setStartDate(start);
    setEndDate(end);
    setFirstOpen(true)
  };

  const renderDateFilterMenu = () => {
    return (

      <div>
        <div className="custom-datepicker">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            placeholderText="Seleccionar fecha"
          />
        </div>
      </div>
    );
  };

  return <div>{renderDateFilterMenu()}</div>;
}

export default FilterOptions;
