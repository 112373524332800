import { publicRequest } from "../../requestMeth";
import { uploadBillFailure, uploadBillStart, uploadBillSuccess } from "../reducers/uploadBillReducer";


export const getPdfBill = async (dispatch, payload, token) => {
    dispatch(uploadBillStart());
    try {
      const res = await publicRequest.post(`/uploadBill`, payload, {
        headers: { token: `Bearer ${token}` }
      });
      dispatch(uploadBillSuccess(res?.data))
      return res; 
    } catch (error) {
      dispatch(uploadBillFailure({}))
      return {error: true, message: "No se pudo obtener datos de la factura. Intente nuevamente."};
    }  
  };