// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-dark {
  background-color: #f1f1f1;
  color: #333;
}
.container-app {
  min-height: 100vh;
  padding-bottom: 50px;
  position: relative;
  margin: 0; /* Asegúrate de que no tenga márgenes */
  min-width: 789px
}

.app-light {
  background-color: #333;
  color: #fff;
}

.theme-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px; 
}

.theme-toggle .light{
  color: black;
}


.theme-toggle:hover {
  color: #007BFF; /* Color de resaltado cuando el mouse pasa sobre el botón */
}

.cont-back {
  width: 20%;
  height: 20px;
  margin-bottom: 3%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,SAAS,EAAE,uCAAuC;EAClD;AACF;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;;AAGA;EACE,cAAc,EAAE,2DAA2D;AAC7E;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,eAAe;EACf,eAAe;AACjB","sourcesContent":[".app-dark {\n  background-color: #f1f1f1;\n  color: #333;\n}\n.container-app {\n  min-height: 100vh;\n  padding-bottom: 50px;\n  position: relative;\n  margin: 0; /* Asegúrate de que no tenga márgenes */\n  min-width: 789px\n}\n\n.app-light {\n  background-color: #333;\n  color: #fff;\n}\n\n.theme-toggle {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n  background-color: transparent;\n  border: none;\n  outline: none;\n  font-size: 20px; \n}\n\n.theme-toggle .light{\n  color: black;\n}\n\n\n.theme-toggle:hover {\n  color: #007BFF; /* Color de resaltado cuando el mouse pasa sobre el botón */\n}\n\n.cont-back {\n  width: 20%;\n  height: 20px;\n  margin-bottom: 3%;\n  display: flex;\n  justify-content: space-between;\n  align-items: baseline;\n  cursor: pointer;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
