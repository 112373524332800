import "./UploadBill.css";
import React, { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import { FaChevronDown } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaInfoCircle } from "react-icons/fa";
import { getAllsupplierCompany } from "../../redux/apiCalls/supplierCompanyCall";
import Swal from "sweetalert2";
import {
  createBill,
  editBill,
  existBill,
  getUploadedBills,
  getPreviousDate
} from "../../redux/apiCalls/dataCall";
import { Toast } from "../../helpers/alert";
import { getAllItemsBill } from "../../redux/apiCalls/itemBills";
import LineItemText from "../../components/UploadBill/LineItemText";
import ListBills from "../../components/UploadBill/ListBills";
import { calculateDays } from "../../helpers/functions";
import { format, isValid } from "date-fns";
import Uploaded from "../../components/UploadBill/UploadPdfBill";
import FloatingHelpIcon from "../../components/HelpSidebar/FloatingHelpIcon";
import { waterAccounts } from "../../helpers/data";

const UploadBill = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorRef = useRef(null);
  const user = useSelector((state) => state.user.currentUser);
  const postBill = useSelector((state) => state.data);
  const [filterOptions, setFilterOptions] = useState({});
  const [billIdChecked, setBillIdChecked] = useState(false);
  const companies = useSelector((state) => state.companies.companies);
  const suppliersBranch = useSelector(
    (state) => state.supplierCompany.supplierCompany
  );
  const itemsBill = useSelector((state) => state.itemsBill.itemsBill);
  let itemsFeching = useSelector((state) => state.itemsBill.isFetching);
  const [errors, setErrors] = useState({});
  const [accountExists2, setAccountExists2] = useState({
    found: false,
    locationId: "",
    supplierId: "",
  });
  const [suppliers, setSuppliers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [billIdExists, setBillIdExists] = useState(false);
  const [billID, setBillID] = useState("");
  const [extractedData, setExtractedData] = useState({
    consolidatedData: [],
    billId: '',
  });

  const [inputs, setInputs] = useState({
    Bill_ID: extractedData?.billId !== "" ? extractedData.billId : '',
    AccountNumber: "",
    readingDate: "",
    invoiceDate: "",
    previousDate: "",
    dueDate: "",
    days: "",
    currency: "",
    user: user?.user,
    registryDate: new Date(),
    dataOrigin: "FACTURA",
    lineItemCost: [
      {
        lineItemText: "Total Factura",
        class: "Total factura",
        consumption: "-1",
        cost: "0",
      },
    ],
  });

  const orderCompanies = companies?.map(company => ({
    value: company?.id_company,
    label: company?.name
  }));
  const [isEmissionDateInvalid, setIsEmissionDateInvalid] = useState(false);
  const [isCurrentReadingDateInvalid, setIsCurrentReadingDateInvalid] = useState(false);
  const [isPreviousReadingDateInvalid, setIsPreviousReadingDateInvalid] = useState(false);
  const [isExpirationDateInvalid, setIsExpirationDateInvalid] = useState(false);

  const adjustToNoon = (date) => {
    if (date) {
      const adjustedDate = new Date(date);
      adjustedDate.setUTCHours(12, 0, 0, 0); // Establecer a las 12 PM (mediodía) en UTC
      return adjustedDate;
    }
    return "";
  };


  useEffect(() => {
    getAllsupplierCompany(dispatch, user?.accessToken);
  }, [dispatch, user.accessToken]);

  useEffect(() => {
    if (inputs?.AccountNumber) {
      getAllItemsBill(
        dispatch,
        user?.accessToken,
        accountExists2?.account?.supplierName
      );
    }
  }, [dispatch, inputs?.AccountNumber]);
  
  useEffect(() => {
    if (inputs.readingDate) {
      const days = calculateDays(inputs.readingDate, inputs.previousDate);
      setInputs((prevInputs) => ({
        ...prevInputs,
        days: days.toString(),
      }));
    }
  }, [inputs.readingDate, inputs.previousDate]);

  useEffect(() => {
    // Se ejecuta solo una vez al cargar el componente
    const currentDate = new Date();
    if (isValid(currentDate)) {
      const formattedStartDate = format(currentDate, "yyyy-MM-dd");

      // Actualiza el state con las fechas iniciales
      setFilterOptions({
        startDate: formattedStartDate,
        endDate: formattedStartDate,
      });
    }
  }, [setFilterOptions]);

  const [hasIncremented, setHasIncremented] = useState(false);


  useEffect(() => {
    if (billIdExists && !hasIncremented) {
  
      const adjustedReadingDate = adjustToNoon(billID?.readingDate);
      const adjustedInvoiceDate = adjustToNoon(billID?.invoiceDate);
      const adjustedPreviousDate = adjustToNoon(billID?.previousDate);
      const adjustedDueDate = adjustToNoon(billID?.dueDate);
      const totalFacturaIndex = billID?.lineItemCost?.findIndex(
        (item) => item.lineItemText === "Total Factura"
      );
      //Si la factura no tiene el concepto de total factura se lo asigna en 0 
      const itemsCost = totalFacturaIndex !== -1 ? billID?.lineItemCost : [...billID?.lineItemCost, {
        lineItemText: "Total Factura",
        class: "Total factura",
        consumption: "-1",
        cost: "0",
      }]
    
      setInputs(prevInputs => ({
        ...prevInputs,
        readingDate: adjustedReadingDate,
        invoiceDate: adjustedInvoiceDate,
        previousDate: adjustedPreviousDate,
        dueDate: adjustedDueDate,
        days: billID?.days || "",
        currency: billID?.currency || "",
        dataOrigin: "FACTURA",
        lineItemCost: itemsCost || [{
          lineItemText: "Total Factura",
          class: "Total factura",
          consumption: "-1",
          cost: "0",
        }],
      }));


      setShowForm(true);
      setHasIncremented(true); // Marca que el incremento ya se realizó
    } else if (!billIdExists) {
      setHasIncremented(false); // Reinicia el estado si `billIdExists` se vuelve falso
    }
  }, [billIdExists, billID?.AccountNumber, accountExists2.account?.numeroCuenta]);
  
  
  useEffect(() => {
    // Reset validation states if the service type changes
    setIsCurrentReadingDateInvalid(false);
    setIsPreviousReadingDateInvalid(false);
    setIsEmissionDateInvalid(false);
    setIsExpirationDateInvalid(false);

    if (accountExists2?.account?.proveedores_bbva?.servicio !== "AGUA" && !accountExists2?.account?.proveedores_bbva?.nombre?.includes('FUSIONSOLAR') && !accountExists2?.account?.proveedores_bbva?.nombre?.includes("DPEC - Peq. Dem. Serv. Gen. Comercial Comunes")) {
      const previousReadingDate = inputs?.previousDate;
      const currentReadingDate = inputs?.readingDate;
      const emissionDate = inputs?.invoiceDate;
      const expirationDate = inputs?.dueDate;
  
      // 1. Validación de Lectura Actual
      const isCurrentReadingDateInvalid =
        currentReadingDate &&
        ((previousReadingDate && currentReadingDate < previousReadingDate) ||
          (emissionDate && currentReadingDate > emissionDate));
  
      // 2. Validación de Lectura Anterior
      const isPreviousReadingDateInvalid =
        previousReadingDate &&
        ((currentReadingDate && previousReadingDate > currentReadingDate) ||
          (emissionDate && previousReadingDate > emissionDate) ||
          (expirationDate && previousReadingDate > expirationDate));
  
      // 3. Validación de Fecha de Emisión
      const isEmissionDateInvalid =
        emissionDate &&
        ((currentReadingDate && emissionDate < currentReadingDate) ||
          (previousReadingDate && emissionDate < previousReadingDate) ||
          (expirationDate && emissionDate > expirationDate));
  
      // 4. Validación de Fecha de Vencimiento
      const isExpirationDateInvalid =
        expirationDate &&
        ((currentReadingDate && expirationDate < currentReadingDate) ||
          (previousReadingDate && expirationDate < previousReadingDate) ||
          (emissionDate && expirationDate < emissionDate));
  
      // Actualizar estados de validación
      setIsCurrentReadingDateInvalid(isCurrentReadingDateInvalid);
      setIsPreviousReadingDateInvalid(isPreviousReadingDateInvalid);
      setIsEmissionDateInvalid(isEmissionDateInvalid);
      setIsExpirationDateInvalid(isExpirationDateInvalid);
    }
  }, [
    inputs?.previousDate,
    inputs?.readingDate,
    inputs?.invoiceDate,
    inputs?.dueDate,
    inputs?.AccountNumber,
    accountExists2?.account?.proveedores_bbva?.servicio
  ]);
  
  
  
const handleUpdateBill = (e) => {
  e.preventDefault();

  if (inputs.Bill_ID.trim() === '') {
    setErrors({
      ...errors,
      Bill_ID: 'El campo ID de factura es obligatorio, por favor completar.',
    });
    Toast.fire({
      icon: "info",
      title: `Por favor, completar el campo 'ID de factura'.`,
    });
    return;
  }

  if (inputs?.previousDate === '' || inputs?.dueDate === '' || inputs?.invoiceDate === '' || inputs?.readingDate === '') {
    setErrors({
      ...errors,
      dueDate: 'Las fechas es obligatoria, por favor completar.',
      previousDate: 'Las fechas es obligatoria, por favor completar.',
      invoiceDate: 'Las fechas es obligatoria, por favor completar.',
      readingDate: 'Las fechas es obligatoria, por favor completar.',
    });
    Toast.fire({
      icon: "info",
      title: `Por favor, completar los campos de fechas.`,
    });
    return;
  }

    // Parsear fechas para comparación
    if (accountExists2?.account?.proveedores_bbva?.servicio !== "AGUA" && !accountExists2?.account?.proveedores_bbva?.nombre?.includes('FUSIONSOLAR') && !accountExists2?.account?.proveedores_bbva?.nombre?.includes("DPEC - Peq. Dem. Serv. Gen. Comercial Comunes")
) {
    const previousDate = new Date(inputs?.previousDate);
    const currentReadingDate = new Date(inputs?.readingDate);
    const emissionDate = new Date(inputs?.invoiceDate);
    const expirationDate = new Date(inputs?.dueDate);
  
    // Validar las fechas
    const isCurrentReadingDateInvalid = currentReadingDate <= previousDate || currentReadingDate > emissionDate;
    const isPreviousReadingDateInvalid = previousDate >= currentReadingDate || previousDate >= emissionDate || previousDate >= expirationDate;
    const isEmissionDateInvalid = emissionDate < currentReadingDate || emissionDate <= previousDate || emissionDate > expirationDate;
    const isExpirationDateInvalid = expirationDate < currentReadingDate || expirationDate <= previousDate || expirationDate < emissionDate;
  
    if (isCurrentReadingDateInvalid || isPreviousReadingDateInvalid || isEmissionDateInvalid || isExpirationDateInvalid) {
      setErrors({
        ...errors,
        readingDate: isCurrentReadingDateInvalid ? 'La fecha de lectura actual debe ser posterior a la lectura anterior y no puede ser posterior a la fecha de emisión.' : '',
        previousDate: isPreviousReadingDateInvalid ? 'La fecha de lectura anterior debe ser anterior a todas las demás fechas.' : '',
        invoiceDate: isEmissionDateInvalid ? 'La fecha de emisión debe ser posterior a la fecha de lectura anterior y debe ser anterior o igual a la fecha de vencimiento.' : '',
        dueDate: isExpirationDateInvalid ? 'La fecha de vencimiento debe ser posterior a fechea de lectura anterior e igual o posterior a todas las demás fechas.' : '',
      });
      Toast.fire({
        icon: "info",
        title: `Por favor, corregir las fechas según las validaciones.`,
      });
      return;
    }
  }
  const { lineItemCost } = inputs;

for (let i = 0; i < lineItemCost.length; i++) {
  const item = lineItemCost[i];
  if (
    (item.consumption === undefined || item.consumption === null || item.consumption === "") &&
    (item.class === 'Energia Consumida' || 
     item.class === 'Agua Consumida' || 
     item.class === 'Gas Cons. a 9300 Kcal/m3' || 
     item.class === 'Energia Renovable') &&
    (item.units.toUpperCase() === 'KWH' || item.units.toUpperCase() === 'M3')
  ) {
    setErrors({
      ...errors,
      consumption: 'El consumo es obligatorio, por favor completar.',
    });
    Toast.fire({
      icon: "info",
      title: `Por favor, completar los campos de consumo para ${item.lineItemText} (${item.class}).`,
    });
    return;
  }
  else if (
    (inputs?.lineItemCost?.length === 1)
  ) {
    
    setErrors({
      ...errors,
      consumption: 'El consumo es obligatorio, por favor completar.',
    });
    Toast.fire({
      icon: "info",
      title: `Por favor, completar campos.`,
    });
    return;
  }
}

    // Preprocess the line items to ensure every item has a cost and consumption
    const updatedLineItems = inputs?.lineItemCost?.map(item => ({
      ...item,
      cost: item?.cost !== undefined && item?.cost !== null ? item?.cost : '0', // ensure 'cost' is set
      consumption: item?.consumption !== undefined && item?.consumption !== null ? item?.consumption : '0' // ensure 'consumption' is set
  }));

  const updatedInputs = {
      ...inputs,
      lineItemCost: updatedLineItems
  };

  let text = updatedInputs.lineItemCost.length === 1 ? "Se editará 1 registro." : `Se editarán varios registros.`;
  Swal.fire({
    title: `${text} ¿Desea continuar?`,
    icon: "info",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sí, continuar.",
    cancelButtonText: "Cancelar",
  }).then((result) => {
    if (result.isConfirmed) {
      try {
        editBill(dispatch, billID?.Bill_ID, updatedInputs, user.accessToken)
          .then((res) => {
            getUploadedBills(dispatch, user?.accessToken, filterOptions);
            setAccountExists2({
              found: false,
              locationId: "",
              supplierId: "",
            });
            setInputs({
              Bill_ID: "",
              AccountNumber: "",
              readingDate: "",
              invoiceDate: "",
              previousDate: "",
              dueDate: "",
              days: "",
              currency: "",
              user: user?.user,
              registryDate: new Date(),
              dataOrigin: "FACTURA",
              lineItemCost: [{
                lineItemText: "Total Factura",
                class: "Total factura",
                consumption: "-1",
                cost: "0",
              }],
            });
            setSuppliers([]);
            setShowForm(false);
            Toast.fire({
              icon: "success",
              title: `Registros editados correctamente.`,
            });
          })
          .catch((err) => {
            console.error("Error during bill update: ", err);
            Toast.fire({
              icon: "error",
              title: `Error al intentar editar la factura: ${err.error ? err.message : "Intente nuevamente"}`,
            });
          });
      } catch (error) {
        console.error("Catch block error: ", error);
        Toast.fire({
          icon: "error",
          title: `Error al intentar editar la factura. Intente nuevamente.`,
        });
      }
    }
  });
};


const handleDataExtracted = (consolidatedData, infoBill) => {
  const filteredData = consolidatedData?.filter(item => item?.cost !== null || item?.consumpiton !== null);
  const adjustedInvoiceDate = adjustToNoon(infoBill?.invoiceDate);
  const adjustedDueDate = adjustToNoon(infoBill?.dueDate);
  setExtractedData({
    consolidatedData: filteredData,
    billId: infoBill?.bill_ID,
  });

  setInputs(prevInputs => ({
    ...prevInputs,
    Bill_ID: infoBill?.bill_ID,
    invoiceDate: adjustedInvoiceDate,
    dueDate: adjustedDueDate,
    dataOrigin: "FACTURA",
    lineItemCost: filteredData,
  }));
};

  const handleBlurBillId = async (e) => {
    if (e.key !== 'Enter') return;
    try {
      const existsBill = await existBill(
        dispatch,
        inputs?.Bill_ID,
        "",
        user?.accessToken
      );
 

      if (existsBill && existsBill?.Bill_ID ) {
        setBillID(existsBill);
        setBillIdExists(true);
        if (existsBill?.AccountNumber !== accountExists2?.numeroCuenta ) {
          Toast.fire({
            icon: "info",
          title: `La factura está asociada a ${existsBill?.locationName}, ${existsBill?.supplier}. Si confirma se cambiara el Nro de cuenta y se mezclarán conceptos de ${existsBill?.locationName} , ${existsBill?.supplier} `,
          });
        }
      } else {
        setBillID({});
        setBillIdExists(false);
      }
      setBillIdChecked(true);  // Establece que la verificación ha sido realizada.
    } catch (error) {
      console.error("Error al verificar la existencia de la factura");
      setBillID({});
      setInputs(prevInputs => ({
        ...prevInputs,
        lineItemCost: [
          {
            lineItemText: "Total Factura",
            class: "Total factura",
            consumption: "-1",
            cost: "0",
          },
        ],
      }));
      setBillIdExists(false);
      setBillIdChecked(true);  // Asumir que la verificación fue realizada aunque falló.
    }
 };
 
 const handleLocationChange = (newValue, actionMeta) => {
    const value = newValue ? newValue?.value : ""; // Obtener el valor seleccionado
    const nameLocation = newValue ? newValue?.label : ""; 

    setAccountExists2((prevAccountExists2) => {
      let updatedAccountExists2 = { ...prevAccountExists2 };

        updatedAccountExists2 = {
          locationId: value,
          locationName: nameLocation
        };

        setSuppliers(
          suppliersBranch?.filter((s) => Number(s?.id_sucursal) === Number(value))
        );

        setShowForm(false);
        setInputs({
          ...inputs,
          lineItemCost: [{
            lineItemText: "Total Factura",
            class: "Total factura",
            consumption: "-1",
            cost: "0",
          }],
        });
        setBillIdExists(false);

        setExtractedData({
          consolidatedData: [],
          billId: '',
        });
      return updatedAccountExists2;
    });

  }
  
  
 const handleInputChange = (e) => {
  const { name, value } = e.target;

  setInputs({
    ...inputs,
    [name]: value,
  });

  if (name === 'Bill_ID') {
      if (value.trim() === '') {
        setErrors({
          ...errors,
          Bill_ID: 'El campo ID de factura es obligatorio, por favor completar.',
        });
      } else {
        setErrors({
          ...errors,
          Bill_ID: '',
        });
      }
      setBillIdChecked(false); // Resetear la verificación cada vez que se cambie el Bill ID
      setBillIdExists(false); // Opcional: Considera también resetear si la factura existe
  }
};


const handleSetAccount2 = (e) => {
  const { name, value } = e.target;
  setAccountExists2((prevAccountExists2) => {
    let updatedAccountExists2 = { ...prevAccountExists2 };

    if (name === "locationId") {

      updatedAccountExists2 = {
        locationId: value,
      };

      setSuppliers(
        suppliersBranch?.filter((s) => Number(s?.id_sucursal) === Number(value))
      );

      setShowForm(false);
      setInputs({
        ...inputs,
        lineItemCost: [{
          lineItemText: "Total Factura",
          class: "Total factura",
          consumption: "-1",
          cost: "0",
        }],
      });
      setBillIdExists(false);
      setExtractedData({
        consolidatedData: [],
        billId: '',
      });
    } else if (name === "supplierId") {
      updatedAccountExists2 = {
        ...updatedAccountExists2,
        supplierId: value,
      };

      let supplierCompany = suppliers?.find( (s) => Number(s?.id_proveedor) === Number(value));

      supplierCompany?.numeroCuenta?.replace(/[\r\n]+/g, "");
      const accNumberCompany = supplierCompany.numeroCuenta ||
      supplierCompany.numeroMedidor ||
      supplierCompany.numeroNis ||
      supplierCompany.numeroContrato;

      if (supplierCompany) {
        getPreviousDate(
          dispatch,
          accNumberCompany,
          user?.accessToken
        )
        .then((res) => {
          if(res?.error){
            Toast.fire({
              icon: "info",
              title: `No se pudo obtener fecha de lectura anterior.`,
            });
          }
          const suppliersWithExtraDay = [
            "Edesur", 
            "LA GENOVEVA - ER",
            "CAMMESA-ER", 
            "CP LOS OLIVOS",
            "LUZ TRES PICOS - ER",
            "FUSIONSOLAR",
            "AYSA - No Residencial No Medido",
            "AYSA - Baldio No Medido",
            "AYSA - Residencial No Medido",
            "ENERGIA_SAN_JUAN T3 BT",
            "Coop._Rivadavia T2 R BT",
            "DPEC - Peq. Dem. Serv. Gen. Comercial Comunes",
            "DPEC - Grande Dem. 50 KW <= POT <300",
            "EPE_Santa_Fe T2  2 B1",
            'EcoGas P1'
          ];
          
          // Verificamos si el supplier está en la lista y sumamos un día si es necesario
          const needsExtraDay = suppliersWithExtraDay.some(keyword => res?.supplier?.includes(keyword)) || waterAccounts?.includes(accNumberCompany);
          
          // Si necesita sumar un día, ajustamos la fecha
          const adjustedReadingDate = new Date(res?.readingDate);
          if (needsExtraDay) {
            
            adjustedReadingDate.setDate(adjustedReadingDate.getDate() + 1);
          }
          const isValidDate = adjustedReadingDate instanceof Date && !isNaN(adjustedReadingDate);
          
          setInputs({
            ...inputs,
            previousDate: isValidDate ? adjustToNoon(adjustedReadingDate) : "",
            AccountNumber: accNumberCompany
          });
        });

        updatedAccountExists2 = {
          ...updatedAccountExists2,
          account: supplierCompany,
          found: true,
        };
        setInputs({
          ...inputs,
          AccountNumber: accNumberCompany
        });
        
        setShowForm(true);
        
        
      }
    }
    return updatedAccountExists2;
  });
};

  const handleResetValues = () => {
    setExtractedData({
      consolidatedData: [],
      billId: '',
    });
    setInputs(prevInputs => ({
      ...prevInputs,
      Bill_ID: "", 
      lineItemCost: [
        {
          lineItemText: "Total Factura",
          class: "Total factura",
          consumption: "-1",
          cost: "0",
        },
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const invalidPattern = /\d+[.,]\d+[.,]/;
    let hasInvalidNumbers = false;

    const newLineItemCost = inputs.lineItemCost.map(item => {
        if (invalidPattern.test(item.cost) || invalidPattern.test(item.consumption)) {
            hasInvalidNumbers = true;
            Toast.fire({
                icon: "error",
                title: `Error en ${item.lineItemText}: Formato de número inválido.`,
            });
            return item; // No se borra el valor, se mantiene igual
        }
        return item;
    });

    if (hasInvalidNumbers) {
        setInputs(prevInputs => ({
            ...prevInputs,
            lineItemCost: newLineItemCost,
        }));
        return; // Evita que el formulario se envíe
    }
    if (inputs?.Bill_ID?.trim() === '') {
      setErrors({
        ...errors,
        Bill_ID: 'El campo ID de factura es obligatorio, por favor completar.',
      });
      Toast.fire({
        icon: "info",
        title: `Por favor, completar el campo 'ID de factura'.`,
      });
      return;
    }

    if (inputs?.previousDate === '' || inputs?.dueDate === '' || inputs?.invoiceDate === '' || inputs?.readingDate === '') {
      setErrors({
        ...errors,
        dueDate: 'Las fechas es obligatoria, por favor completar.',
        previousDate: 'Las fechas es obligatoria, por favor completar.',
        invoiceDate: 'Las fechas es obligatoria, por favor completar.',
        readingDate: 'Las fechas es obligatoria, por favor completar.',
      });
      Toast.fire({
        icon: "info",
        title: `Por favor, completar los campos de fechas.`,
      });
      return;
    }
    if (accountExists2?.account?.proveedores_bbva?.servicio !== "AGUA" && !accountExists2?.account?.proveedores_bbva?.nombre?.includes('FUSIONSOLAR' && !accountExists2?.account?.proveedores_bbva?.nombre?.includes("DPEC - Peq. Dem. Serv. Gen. Comercial Comunes"))
) {
      // Parsear fechas para comparación
      const previousDate = new Date(inputs?.previousDate);
      const currentReadingDate = new Date(inputs?.readingDate);
      const emissionDate = new Date(inputs?.invoiceDate);
      const expirationDate = new Date(inputs?.dueDate);
    
      // Validar las fechas
      const isCurrentReadingDateInvalid = currentReadingDate <= previousDate || currentReadingDate > emissionDate;
      const isPreviousReadingDateInvalid = previousDate >= currentReadingDate || previousDate >= emissionDate || previousDate >= expirationDate;
      const isEmissionDateInvalid = emissionDate < currentReadingDate || emissionDate <= previousDate || emissionDate > expirationDate;
      const isExpirationDateInvalid = expirationDate < currentReadingDate || expirationDate <= previousDate || expirationDate < emissionDate;
    
      if (isCurrentReadingDateInvalid || isPreviousReadingDateInvalid || isEmissionDateInvalid || isExpirationDateInvalid) {
        setErrors({
          ...errors,
          readingDate: isCurrentReadingDateInvalid ? 'La fecha de lectura actual debe ser posterior a la lectura anterior y no puede ser posterior a la fecha de emisión.' : '',
          previousDate: isPreviousReadingDateInvalid ? 'La fecha de lectura anterior debe ser anterior a todas las demás fechas.' : '',
          invoiceDate: isEmissionDateInvalid ? 'La fecha de emisión debe ser posterior a la fecha de lectura anterior y debe ser anterior o igual a la fecha de vencimiento.' : '',
          dueDate: isExpirationDateInvalid ? 'La fecha de vencimiento debe ser posterior a fechea de lectura anterior e igual o posterior a todas las demás fechas.' : '',
        });
        Toast.fire({
          icon: "info",
          title: `Por favor, corregir las fechas según las validaciones.`,
        });
        return;
      }
    }
const { lineItemCost } = inputs;

for (let i = 0; i < lineItemCost.length; i++) {
  const item = lineItemCost[i];

  if (
    (item.consumption === undefined || item.consumption === null || item.consumption === "") &&
    (item.class === 'Energia Consumida' || 
     item.class === 'Agua Consumida' || 
     item.class === 'Gas Cons. a 9300 Kcal/m3' || 
     item.class === 'Energia Renovable') &&
    (item.units.toUpperCase() === 'KWH' || item.units.toUpperCase() === 'M3')
  ) {
    setErrors({
      ...errors,
      consumption: 'El consumo es obligatorio, por favor completar.',
    });
    Toast.fire({
      icon: "info",
      title: `Por favor, completar los campos de consumo para ${item.lineItemText} (${item.class}).`,
    });
    return;
  }
  else if (
    (inputs?.lineItemCost?.length === 1)
  ) {
    
    setErrors({
      ...errors,
      consumption: 'El consumo es obligatorio, por favor completar.',
    });
    Toast.fire({
      icon: "info",
      title: `Por favor, completar campos.`,
    });
    return;
  }
}

    let text =
      inputs?.lineItemCost.length === 1
        ? "Se creará 1 registro."
        : `Se crearán varios registros.`;
    Swal.fire({
      title: `${text} ¿Desea continuar?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, continuar.",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          createBill(dispatch, inputs, user.accessToken)
            .then((res) => {
              getUploadedBills(dispatch, user?.accessToken, filterOptions);
              // navigate(`/uploadBill`);
              setAccountExists2({
                found: false,
                locationId: "",
                supplierId: "",
              });
              setInputs({
                Bill_ID: "",
                AccountNumber: "",
                readingDate: "",
                invoiceDate: "",
                previousDate: "",
                dueDate: "",
                days: "",
                currency: "",
                user: user?.user,
                registryDate: new Date(),
                dataOrigin: "FACTURA",
                lineItemCost: [{
                  lineItemText: "Total Factura",
                  class: "Total factura",
                  consumption: "-1",
                  cost: "0",
                }],
              });
              setSuppliers([]);
              setShowForm(false);
              Toast.fire({
                icon: "success",
                title: `Registros creados.`,
              });
            })
            .catch((err) => {
              Toast.fire({
                icon: "error",
                title: `Ocurrió un error al intentar crear la factura. ${
                  err.error ? err.message : "Intente nuevamente"
                }`,
              });
            });
        } catch (error) {
          Toast.fire({
            icon: "error",
            title: `Ocurrió un error al intentar crear la factura.`,
          });
        }
      }
    });
  };


  return (
    <div className="uploadbill-container">
      <div className="back-bill" onClick={() => navigate(`/`)}>
        <i>
          <FaArrowLeft />
        </i>
        <p>Volver</p>
      </div>
      <div className="cont-uploadbills">
        <h2>Cargar factura</h2>
        <ListBills />
      </div>
      <form>
        <div className="form-group">
          <div>
            <label htmlFor="locationId">Selecciona sucursal</label>
            <Select
              id="locationId"
              name="locationId"
              defaultValue={[]}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              placeholder="Sucursal"
              options={orderCompanies}
              components={{ DropdownIndicator: () => <FaChevronDown /> }}
              onChange={handleLocationChange}
              value={{ label: accountExists2.locationName, value: accountExists2.locationId }}
              
            >
              Sucursal
              <i>
                <FaChevronDown />
              </i>
            </Select>
          </div>
          {suppliers?.length > 0 ? (
            <div>
              <label htmlFor="supplierId">Selecciona proveedor</label>
              <select
                id="supplierId"
                name="supplierId"
                value={accountExists2.supplierId || ""}
                onChange={handleSetAccount2}
                onClick={handleResetValues}
              >
                <option disabled value="">
                  Selecciona una opción
                </option>
                {suppliers?.filter(supplier => supplier.estado === "ACTIVO").map((c) => (
                <option key={c.id} value={c.id_proveedor}>
                    {c.supplierName}
                </option>
                 ))}
              </select>
            </div>
          ) : null}
        </div>
        {itemsFeching && (
      <div style={{ position: "fixed", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: "9999", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ textAlign: "center" }}>
          <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem" }}>
            <span className="visually-hidden">Cargando...</span>
          </div>
          <p style={{ marginTop: "10px" }}>Cargando...</p>
        </div>
      </div>
    )}
        {showForm && accountExists2.found ? (
          <> 
            {showForm && accountExists2.found ? (
              <div className="">
                <p>Número de cuenta: {inputs?.AccountNumber}</p>
              </div>
            ) : null}
            <Uploaded onDataExtracted={handleDataExtracted} item={itemsBill} account={accountExists2?.account} user={user} setBillIdChecked={setBillIdChecked} setBillIdExists={setBillIdExists}/>
            <div className="form-group cont-dates">
              <div className="dateform" ref={errorRef}>
                <label htmlFor="Bill_ID">ID de factura: </label>
                <input
                  className={billIdExists ? "billExists" : ""}
                  type="text"
                  id="Bill_ID"
                  name="Bill_ID"
                  placeholder= {extractedData?.billId ? extractedData.billId : "Ingrese ID de factura"}
                  value={inputs?.Bill_ID || ""}
                  onChange={(e) => {
                    handleInputChange(e);
                    handleSetAccount2(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleBlurBillId(e);
                    }
                  }}
                  required
                />
                {billIdExists  && (
                  <p className="error-message">
                    Esta factura ya se encuentra cargada a este nroCuenta. {billID?.AccountNumber}
                  </p>
                )}
                {errors.Bill_ID && (
                    <span className="error-billId">{errors.Bill_ID}</span>
                )}
              </div>
              <div className="dateform">
                <label htmlFor="currency">Moneda</label>
                <select
                  id="currency"
                  name="currency"
                  value={inputs.currency}
                  onChange={handleInputChange}
                >
                  <option value="AR">Peso argentino</option>
                </select>
              </div>
              <div className="dateform">
                <label htmlFor="dataOrigin">Origen de los datos</label>
                <select
                  id="dataOrigin"
                  name="dataOrigin"
                  value={inputs.dataOrigin}
                  onChange={handleInputChange}
                >
                  <option value="FACTURA">FACTURA</option>
                  <option value="ESTIMADO">ESTIMADO</option>
                </select>
              </div>
            </div>
            <div className="form-group cont-dates">
              <div className="dateform">
                <label htmlFor="readingDate">Fecha de lectura actual</label>
                <DatePicker
                  id="readingDate"
                  name="readingDate"
                  selected={inputs.readingDate}
                  onChange={(date) => setInputs({ ...inputs, readingDate: date })}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Seleccione una fecha"
                  className={`form-control ${isCurrentReadingDateInvalid ? 'is-invalid' : ''}`}
                  title={isCurrentReadingDateInvalid ? errors?.readingDate : ''}
                />
              </div>
              <div className="dateform">
                <label htmlFor="previousDate">Fecha de lectura anterior</label>
                <DatePicker
                  id="previousDate"
                  name="previousDate"
                  selected={inputs.previousDate}
                  onChange={(date) => setInputs({ ...inputs, previousDate: date })}
                  
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Seleccione una fecha"
                  className={`form-control ${isPreviousReadingDateInvalid ? 'is-invalid' : ''}`}
                  title={isPreviousReadingDateInvalid ? errors?.previousDate : ''}
                />
              </div>
              <div className="dateform">
                <label htmlFor="invoiceDate">Fecha de emisión</label>
                <DatePicker
                  id="invoiceDate"
                  name="invoiceDate"
                  selected={inputs.invoiceDate}
                  onChange={(date) => setInputs({ ...inputs, invoiceDate: date })}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Seleccione una fecha"
                  className={`form-control ${isEmissionDateInvalid ? 'is-invalid' : ''}`}
                  title={isEmissionDateInvalid ? errors?.invoiceDate : ''} // Agrega el título aquí
                />
              </div>
              <div className="dateform">
                <label htmlFor="dueDate">Fecha de vencimiento</label>
                <DatePicker
                  id="dueDate"
                  name="dueDate"
                  selected={inputs.dueDate}
                  onChange={(date) => setInputs({ ...inputs, dueDate: date })}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Seleccione una fecha"
                  className={`form-control ${isExpirationDateInvalid ? 'is-invalid' : ''}`}
                  title={isExpirationDateInvalid ? errors?.dueDate : ''} // Agrega el título aquí
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="days">Días</label>
              <input
                type="number"
                id="days"
                name="days"
                placeholder="Ingrese días"
                onChange={handleInputChange}
                value={inputs.days}
              />
            </div>
            <div className="form-itemText">
              <label htmlFor="dueDate">Complete todos los conceptos: </label>
              <i title="Se genera un registro por cada concepto">
                <FaInfoCircle />
              </i>
            </div>
            <LineItemText
              itemsBill={itemsBill}
              setInputs={setInputs}
              inputs={inputs}
              errors={errors}
              setErrors={setErrors}
              extractedData={extractedData}
              supplierName={accountExists2?.account?.supplierName || ""}
            />
           {!billIdExists && (
            <div>
              <button
                type="button"
                className={errors.otherCharges || errors.Bill_ID || !billIdChecked ? "disabled-create" : ""}
                onClick={handleSubmit}
                disabled={errors.otherCharges || errors.Bill_ID || !billIdChecked || billIdExists}
              >
                {postBill.billFetching ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Crear"
                )}
              </button>
              {!billIdChecked && (
                <p className="error-message">
                  Debes buscar si la factura ya existe dándole "Enter" en el Bill ID.
                </p>
              )}
            </div>
          )}

          </>
        ) : null}
        
      </form>
      {billIdExists && (
        <div className="form-group">
          <button type ="button" onClick={handleUpdateBill}>Actualizar factura</button>
        </div>
      )}
      <FloatingHelpIcon infoHelp={"uploadBill"}/>
    </div>
  );
};

export default UploadBill;
