import "./Audit.css"
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AuditReport from "../../components/Reports/AuditReports/AuditReport";
import AuditPowerReport from "../../components/Reports/AuditReports/AuditPowerReport";
import AuditEnergyReport from "../../components/Reports/AuditReports/AuditEnergyReport";
import AuditSostentabilidadExcel from "../../components/Reports/AuditReports/AuditSostentabilidadReport";
import { getCO2Data } from "../../redux/apiCalls/dataCall";

const Audit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const suppliers = useSelector((state) => state.supplier.supplier);
  const co2 = useSelector((state) => state.data.co2Data);
  const co2dataFetching = useSelector((state) => state.data.co2Data);
  const co2Fetching = useSelector((state) => state.data.isFetchingCo2);
  const offices = useSelector((state) => state.companies.companies);
  const [filters, setFilters] = useState({
    year: '',
    service: '',
    supplierSelected: '',
  });

  const years = ["2023", "2024"];

  // Crear opciones de años para react-select
  const yearOptions = years.map(year => ({
    value: year,
    label: year,
  }));


  useEffect(() => {
    if (!co2 ) {
      getCO2Data(dispatch, user?.accessToken)
    }
  }, []);
  


  // Lista de proveedores excluidos
  const proveedoresIncluidos = [
    "Edesur", "Edenor", "Edelap", "Edemsa", "Edet", 
    "Epec", "Epe_Santa_Fe", "Enersa", "Edea", "Eden", "ENERGIA_SAN_JUAN", "Edesa","EPEN"
  ];

// Agrupamos los proveedores por la primera palabra de su nombre
const groupedSuppliers = suppliers?.reduce((acc, supplier) => {
  const firstWord = supplier?.nombre?.trim().split(' ')[0].toUpperCase();
  
  // Verificamos si la primera palabra está en la lista de proveedores incluidos
  if (proveedoresIncluidos.some(nombre => firstWord.includes(nombre.toUpperCase()))) {
    if (!acc[firstWord]) {
      acc[firstWord] = { nombre: firstWord, servicio: supplier?.servicio || '' };
    }
  }

  return acc;
}, {});

const supplierOptions = Object.values(groupedSuppliers)
  .filter(supplier => supplier.nombre !== "EDESAL" && supplier.nombre !== "EDESA_AGUA")  // Filtramos EDESAL y EDESA_AGUA
  .map(supplier => ({
    value: supplier.nombre,
    label: supplier.nombre
  }));

// Añadimos la opción "Otros Proveedores"
supplierOptions.push({
  value: 'OTROS',
  label: 'OTROS'
});


  const handleYearChange = (selectedOption) => {
    setFilters((prev) => ({
      ...prev,
      year: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleSupplier = (selectedOption) => {
    if (selectedOption) {
      if (selectedOption.value === 'OTROS') {
        // Si se selecciona "OTROS", limpiamos el proveedor seleccionado
        setFilters((prev) => ({
          ...prev,
          supplierSelected: '',  // No selecciona proveedor específico
          otrosProveedores: true, // Activamos la búsqueda para otros proveedores
          service: '', // Limpiar el servicio
        }));
      } else {
        // Si se selecciona un proveedor específico
        const selectedSupplier = suppliers.find(supplier => 
          supplier.nombre.toUpperCase().includes(selectedOption.value.toUpperCase())
        );
        setFilters((prev) => ({
          ...prev,
          supplierSelected: selectedOption.value,  // Nombre del proveedor
          otrosProveedores: false, // Desactivamos la búsqueda de otros proveedores
          service: selectedSupplier?.servicio || '',  // Servicio del proveedor
        }));
      }
    } else {
      setFilters((prev) => ({
        ...prev,
        supplierSelected: '',
        otrosProveedores: false,
        service: '',
      }));
    }
  };
  
  

  const consumoSostentabilidad = {};

  // Obtener el mes y año actual
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  
  // Obtener el mes y año hace un año
  const lastYearDate = new Date(currentDate);
  lastYearDate.setFullYear(currentYear - 1);
  const lastYearMonth = 0; // Comienza desde enero del año pasado
  const lastYearYear = lastYearDate.getFullYear();
  
  // Función para obtener el nombre del mes a partir de su número
  const getMonthName = (monthNumber) => {
    const months = [
      "enero", "febrero", "marzo", "abril", "mayo", "junio",
      "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
    return months[monthNumber];
  };
  
  const monthsToFetch = [];
  for (let year = lastYearYear; year <= currentYear; year++) {
    const startMonth = (year === lastYearYear) ? lastYearMonth : 0;
    const endMonth = (year === currentYear) ? currentMonth : 11;
  
    for (let month = startMonth; month <= endMonth; month++) {
      monthsToFetch.push({ year, month });
    }
  }
  
  monthsToFetch.forEach(({ year, month }) => {
    const monthName = getMonthName(month);
    const monthDataKey = `${monthName} ${year}`;
    const monthData = co2 && co2.groupedByMonth ? co2.groupedByMonth[monthDataKey] : undefined;
  
    // Inicializar consumoSostentabilidad[monthDataKey] si no está definido
    if (!consumoSostentabilidad[monthDataKey]) {
      consumoSostentabilidad[monthDataKey] = [];
    }
  
    // Agregar datos existentes para el mes actual
    if (monthData && monthData?.length > 0) {
      monthData.forEach(data => {
        consumoSostentabilidad[monthDataKey].push({
          office: data?.office,
          consumoTotalMes: parseFloat(data?.consumoTotalMes),
          mesProrrateado: data?.mesProrrateado,
          unidad: data?.unidad || "",
          tiposConsumo: data?.tiposConsumo || [],
          days: data?.diasCalculados
        });
      });
    }
  
    offices?.forEach(office => {
      if (!consumoSostentabilidad[monthDataKey].some(item => item?.office === office?.name)) {
        consumoSostentabilidad[monthDataKey].push({
          office: office?.name,
          consumoTotalMes: 0.00,
          mesProrrateado: monthDataKey,
          unidad: "",
          tiposConsumo: [],
        });
      }
    });
  });


  return (
    <div className='users-list'>
      <div
        className="cont-back cont-back-users"
        onClick={() => {
          navigate(`/`);
        }}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Escritorio</p>
      </div>
      <div>
        <h5>AUDITORÍA</h5>
        <p className='users'>Selecciona año y proveedor para la descarga de reportes</p>
        <div className="audit-filters">
          <div className="audit-select">
            <Select
              defaultValue={null}
              isMulti={false}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              controlShouldRenderValue={true}
              placeholder="Año"
              options={yearOptions}
              components={{ DropdownIndicator: () => <FaChevronDown /> }}
              className={`filter-button-selected`}
              onChange={handleYearChange}
              value={yearOptions.find(option =>
                option.value === filters.year
              )}
            />
          </div>
          <div className="audit-select">
          <Select
            defaultValue={null}
            isMulti={false}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            controlShouldRenderValue={true}
            isClearable={true} // Agregar la funcionalidad de limpiar selección
            placeholder="Proveedor"
            options={supplierOptions}
            components={{ DropdownIndicator: () => <FaChevronDown /> }}
            className={`filter-button-selected`}
            onChange={handleSupplier}
            value={supplierOptions.find(
              (option) =>
                option.value === filters.supplierSelected ||
                (filters.otrosProveedores && option.value === 'OTROS')
            )}
          />
        </div>
        </div>
        {filters.year ? (
        <div className="audit-contdownload">
          <AuditReport filters={filters}/>
          {/* Renderiza solo si se ha seleccionado un proveedor */}
          {(filters.supplierSelected || filters.otrosProveedores) && (
            <>
              <AuditPowerReport filters={filters}/>
              <AuditEnergyReport filters={filters}/>  
            </>
          )}
        </div>      
      ) : ""}          
              <div className="audit-contdownload2" >
                {co2Fetching || !co2dataFetching? (
               <button className="btn-reportDisable" disabled>Generando reporte prorrateo...</button> 
                  ) : (
                <AuditSostentabilidadExcel  data={consumoSostentabilidad} />
                )}   </div>
      </div>
    </div>
  );
};

export default Audit;
