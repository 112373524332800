import React from "react";
import domtoimage from "dom-to-image";
import { FaFileDownload } from "react-icons/fa";
import { provinces } from "../../helpers/infoFilter";
import jsPDF from 'jspdf';

let nameProvinces;

const ReportSostentabilidadPdf = ({ filterOptions }) => {
  nameProvinces = filterOptions.provinces.map(value => {
    const province = provinces.find(p => p.value === value);
    return province ? province.name : value;
  }).join(", ");

  const captureDivAsImage = async (divId) => {
    const divToCapture = document.getElementById(divId);

    if (!divToCapture) {
      console.error(`Element with id ${divId} not found`);
      return null;
    }

    // Capture the div as an image
    const imageDataUrl = await domtoimage.toPng(divToCapture);

    return imageDataUrl;
  };

  const handlePdfDownload = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    let yCoordinate = 10;

    pdf.text('SOSTENTABILIDAD', 10, yCoordinate);
    yCoordinate += 10;

    const additionalTextLines = [
      filterOptions.startDate ? `Fechas: ${filterOptions.startDate} - ${filterOptions.endDate}` : '',
      filterOptions.provinces.length > 0 ? `Provincias: ${nameProvinces}` : '',
      filterOptions.companies.length > 0 ? `Sucursales: ${filterOptions.companies.join(', ')}` : '',
      filterOptions.utilities.length > 0 ? `Servicio: ${filterOptions.utilities.join(', ')}` : ''
    ];

    additionalTextLines.forEach(line => {
      if (line) {
        pdf.setFontSize(8);
        pdf.text(line, 12, yCoordinate);
        yCoordinate += 5;
      }
    });

    // Add a small delay to ensure all elements are rendered
    await new Promise(resolve => setTimeout(resolve, 500));

    const divsToCapture = [
      "divSostentabilidad",
      "divSostentabilidad1",
      "divSostentabilidad2",
      "divSostentabilidad3"
    ];

    const imageDataUrls = await Promise.all(divsToCapture.map(divId => captureDivAsImage(divId)));

    const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
    const imageMargin = 5; // Margin between images

    // Place images on the first row
    let xCoordinate = 10; // Starting x-coordinate
    let rowHeight = 0; // Track the maximum height in the row

    for (let i = 0; i < 3; i++) {
      if (i < imageDataUrls.length) {
        const imageDataUrl = imageDataUrls[i];
        if (imageDataUrl) {
          const imgProps = pdf.getImageProperties(imageDataUrl);
          const imageWidth = (pdfWidth - (3 - 1) * imageMargin) / 3;
          const pdfHeight = (imgProps.height * imageWidth) / imgProps.width;

          pdf.addImage(imageDataUrl, 'PNG', xCoordinate, yCoordinate, imageWidth, pdfHeight);
          xCoordinate += imageWidth + imageMargin;
          rowHeight = Math.max(rowHeight, pdfHeight);
        }
      }
    }

    yCoordinate += rowHeight + 10; // Move down for the next set of images
    xCoordinate = 10; // Reset x-coordinate for the next row

    // Handle divSostentabilidad3 separately with adjusted widths
    const divSostentabilidad3 = imageDataUrls[3];
    if (divSostentabilidad3) {
      const imgProps = pdf.getImageProperties(divSostentabilidad3);
      const percentageWidth = 100; // Width in percentage
      const imageWidth = (percentageWidth / 100) * pdfWidth; // Calculate width in mm
      const pdfHeight = (900 * imageWidth) / imgProps.width;

      // Second image
      pdf.addImage(divSostentabilidad3, 'PNG', xCoordinate, yCoordinate, imageWidth, pdfHeight);
      xCoordinate = 20; // Reset x-coordinate
      yCoordinate += pdfHeight + 10; // Move down for the next section
    }

    pdf.save("report_sostentabilidad.pdf");
  };

  return (
    <b onClick={handlePdfDownload}>
      <FaFileDownload />
      Descargar PDF
    </b>
  );
};

export default ReportSostentabilidadPdf;
