import { Toast } from "../../helpers/alert";
import "./LineItemText.css";
import React, { useEffect, useState } from "react";

function validar(otherCharges) {
  let errors = {};
  const regex = /^[0-9;.,\-\s]+$/;

  if (otherCharges && !regex.test(otherCharges)) {
    errors.otherCharges =
      "Los valores deben ser numéricos. En caso de querer agregar varios separarlos con ; (punto y coma). No ingresar letras.";
  }

  return errors;
}

const LineItemText = ({ itemsBill, setInputs, inputs, errors, setErrors, extractedData, supplierName  }) => {
  const [showCharge, setShowCharge] = useState(false);
  const [displayValues, setDisplayValues] = useState({}); // Estado separado para los valores originales
// Agrega un identificador único a cada elemento en itemsBillFiltered y inputs.lineItemCost, valida que el proveedor seleccionado sea igual al proveedor de los conceptos
const itemsBillFiltered = itemsBill?.filter((item) => {
  const isNotTotalFactura = item?.lineItemText !== "Total Factura";
  const isSupplierMatch = supplierName?.includes('FUSIONSOLAR')
    ? (item?.proveedor)?.toUpperCase() === supplierName?.toUpperCase()
    : true;
  return isNotTotalFactura && isSupplierMatch;
}).map((item, index) => ({ ...item, id: `item_${index}` }));

const updatedLineItemCost = itemsBillFiltered.map((item) => ({
  ...item,
  id: `item_${item.id}`, // Asegúrate de que el identificador sea único
}));

  const totalCost = inputs.lineItemCost?.reduce((acc, item) => {
    if (item.lineItemText !== "Total Factura") {
      const costWithoutComma = typeof item.cost === 'string' ? item.cost.replace(",", ".") : item.cost;
      const costValue = parseFloat(costWithoutComma);
  
      if (!isNaN(costValue)) {
        if (costValue === -1) {
          return acc; // Ignora el valor -1
        } else {
          return costValue >= 0 ? acc + costValue : acc - Math.abs(costValue);
        }
      }
    }
    return acc;
  }, 0);


  useEffect(() => {
    if (totalCost !== undefined && !isNaN(totalCost)) {
      const totalFacturaIndex = inputs.lineItemCost.findIndex(
        (item) => item.lineItemText === "Total Factura"
      );

      if (totalFacturaIndex !== -1) {
        const updatedLineItemCost = [...inputs.lineItemCost];
        updatedLineItemCost[totalFacturaIndex] = {
          ...updatedLineItemCost[totalFacturaIndex],
          cost: totalCost.toFixed(2).toString(),
        };

        setInputs({
          ...inputs,
          lineItemCost: updatedLineItemCost,
        });
      } else {
        const unitTotal = itemsBill.find((i) => i.class === "Total factura");
        setInputs({
          ...inputs,
          lineItemCost: [
            ...inputs.lineItemCost,
            {
              lineItemText: "Total Factura",
              class: "Total factura",
              consumption: "-1",
              cost: totalCost.toString(),
              units: unitTotal?.unit,
            },
          ],
        });
      }
    }
  }, [totalCost]);

  // function validar(otherCharges) {
  //   let errors = {};
  //   // Expresión regular para validar números que permiten punto y coma antes de los signos
  //   const regex = /^(?:-?\d+(\.\d+)?)(; -?\d+(\.\d+)?)*$/;
  
  //   // Comprobar si los signos negativos no están precedidos por un punto y coma
  //   const hasInvalidNegative = /(?<!;)-\d+/.test(otherCharges); // Uso de lookbehind para verificar el signo negativo
  
  //   if (otherCharges && !regex.test(otherCharges) || hasInvalidNegative) {
  //     errors.otherCharges =
  //       "Los valores deben ser numéricos. En caso de querer agregar varios, sepáralos con ';' (punto y coma). No ingreses letras y asegúrate de que los signos negativos estén precedidos por un punto y coma.";
  //   }
  
  //   return errors;
  // }
  
  
  const handleOtherCharge = (e, itemName, itemClass, unit) => {
    e.preventDefault();
    let values = e.target.value; // Captura el valor del input
    setErrors(validar(values)); // Valida el valor ingresado

    // Separa los valores por punto y coma
    values = values.trim().split(";").filter(Boolean);

    // Calcula el total solo si hay valores
    const total = values.reduce((total, parte) => {
        const valueNumber = parseFloat(parte.replace(",", "."));

        if (!isNaN(valueNumber)) {
            return total + valueNumber;
        } else {
            return total; // Si no es un número, lo ignora
        }
    }, 0);

    const itemFoundIndex = inputs.lineItemCost.findIndex(
        (item) => item.lineItemText === itemName && item.class === itemClass
    );

    // Si existe, actualiza el valor
    if (itemFoundIndex !== -1) {
        const updatedLineItemCost = [...inputs.lineItemCost];
        updatedLineItemCost[itemFoundIndex] = {
            ...updatedLineItemCost[itemFoundIndex],
            total: e.target.value, // Guarda el valor de entrada directo (múltiples valores)
            cost: total.toFixed(2), // Guarda el total calculado
        };

        setInputs({
            ...inputs,
            lineItemCost: updatedLineItemCost,
        });
    } else {
        // Si no existe, lo agrega
        setInputs({
            ...inputs,
            lineItemCost: [
                ...inputs.lineItemCost,
                {
                    lineItemText: itemName,
                    class: itemClass,
                    total: e.target.value, // Guarda el valor de entrada directo (múltiples valores)
                    cost: total.toFixed(2), // Guarda el total calculado
                    consumption: "-1",
                    units: unit,
                },
            ],
        });
    }
};
  
  

  // useEffect(() => {
  //   const updatedLineItemCost = itemsBillFiltered.map((item) => {
  //     const matchingItem = extractedData.consolidatedData?.find((data) => {
  //       const itemNombre = data.lineItemText.toLowerCase();
  //       const itemText = item.lineItemText.toLowerCase();
  //       return itemText.includes(itemNombre);
  //     });
  
  //     return {
  //       lineItemText: item.lineItemText,
  //       class: item.class,
  //       consumption: matchingItem?.consumption,
  //       units: item.consumptionUnit,
  //       cost: matchingItem?.cost,
  //     };
  //   });
  
  //   // Filter out objects with undefined cost or consumption
  //   const filteredLineItemCost = updatedLineItemCost.filter(
  //     (item) => item.cost !== undefined && item.consumption !== undefined
  //   );
  
  //   setInputs({
  //     ...inputs,
  //     lineItemCost: filteredLineItemCost,
  //   });
  // }, [extractedData]);
  
  const handleItemChange = (e, itemText, itemClass, costUnit, consumptionUnit) => {
    const { name, value } = e.target;
    const regex = /^[0-9;.,\-\+\s]*$/;
  
    // Validar solo si el campo es "cost" o "consumption"
    if ((name === "cost" || name === "consumption") && !regex.test(value)) {
      setErrors({
        ...errors,
        [name]: "Solo se permiten números y punto y coma (;), sin operaciones ni letras.",
      });
      return;
    } else {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  
    const updatedLineItemCost = [...inputs.lineItemCost];
    const itemFoundIndex = updatedLineItemCost.findIndex(
      (item) => item.lineItemText === itemText
    );
  
    if (itemFoundIndex !== -1) {
      const currentItem = updatedLineItemCost[itemFoundIndex];
      
      // Actualizar el campo correcto dependiendo del input que está cambiando
      if (name === "cost") {
        currentItem.cost = value; // Actualiza solo el costo
      } else if (name === "consumption") {
        currentItem.consumption = value; // Actualiza solo el consumo
      }
  
      setInputs({
        ...inputs,
        lineItemCost: updatedLineItemCost,
      });
  
      // Actualiza displayValues correctamente según el campo (cost o consumption)
      setDisplayValues((prevValues) => ({
        ...prevValues,
        [`${itemText}_${name}`]: value, // Usamos un identificador único para cada campo (cost o consumption)
      }));
      
    } else {
      const newItem = {
        lineItemText: itemText,
        class: itemClass,
        units: consumptionUnit && consumptionUnit !== "null" ? consumptionUnit.trim() : costUnit.trim(),
        [name]: value,
      };
  
      setInputs({
        ...inputs,
        lineItemCost: [...inputs.lineItemCost, newItem],
      });
  
      setDisplayValues((prevValues) => ({
        ...prevValues,
        [`${itemText}_${name}`]: value, // Usamos un identificador único para cada campo (cost o consumption)
      }));
    }
  };
  
  const handleBlurItem = (e, itemText) => {
    const { value, name } = e.target;

    // Reemplazar todas las comas por puntos antes de cualquier validación
    let formattedValue = value.replace(/,/g, ".");

    if ((name === "cost" || name === "consumption") && formattedValue.includes(";")) {
        const values = formattedValue.split(";").map(val => val.trim());

        // Verificar si hay valores con más de un punto decimal (inválidos)
        const hasInvalidNumbers = values.some(val => (val.match(/\./g) || []).length > 1);

        if (hasInvalidNumbers) {
            Toast.fire({
                icon: "error",
                title: `Error en ${itemText}: Formato de número inválido (más de un punto decimal).`,
            });
            return; // No actualizamos el estado si hay error
        }

        // Convertimos los valores a número y verificamos si son válidos
        const numericValues = values.map(val => parseFloat(val)).filter(val => !isNaN(val));

        if (numericValues.length !== values.length) {
            Toast.fire({
                icon: "error",
                title: `Error en ${itemText}: Uno o más valores no son números válidos.`,
            });
            return; // No actualizamos el estado si hay error
        }

        // Sumar los valores válidos y asegurarnos de que el resultado tenga dos decimales
        formattedValue = numericValues.reduce((acc, curr) => acc + curr, 0).toFixed(2);
    }

    // Actualizar el estado solo si el valor es válido
    setInputs(prevInputs => {
        const updatedLineItemCost = prevInputs.lineItemCost.map(item =>
            item.lineItemText === itemText ? { ...item, [name]: formattedValue } : item
        );

        return { ...prevInputs, lineItemCost: updatedLineItemCost };
    });
};

  return (
    <>
      <div>
        <table className="table table-bordered">
          <thead className="itemText-thead">
            <tr>
              <th>CONCEPTO</th>
              <th>CONSUMO</th>
              <th>COSTO</th>
            </tr>
          </thead>
          <tbody>
          {itemsBillFiltered?.map((item, index) => (
            <tr className="itemText-row" key={item.id}>
          <td>
            {item.lineItemText} - {item.class}
          </td>
          <td>
  {item.consumptionUnit && item.consumptionUnit !== "null" ? (
    // Lógica específica para "Energía reactiva" y EDEN T2 B1
    (
      item.lineItemText?.includes("Energía reactiva") &&
      item.proveedor?.includes("EDEN T2 B1")
    ) ||
    // Nueva validación para Coop. Trenque Lauquen T2BT con Potencia Contratada o Energia Consumida
    (
      (item.class?.includes("Potencia Contratada") ||
        item.class?.includes("Energia Consumida")) &&
      item.proveedor?.includes("Coop. Trenque Lauquen T2BT")
    ) ? (
      <div className="itemColumn">
        <input
          type="text"
          id={`consumption_${item.id}`}
          value={
            (displayValues?.[`${item?.lineItemText}_consumption`] ||
              inputs?.lineItemCost?.find((input) => {
                const inputText = input?.lineItemText?.toLowerCase()?.trim();
                const itemText = item?.lineItemText?.toLowerCase()?.trim();
                return inputText === itemText;
              })?.consumption) ?? ""
          }
          name="consumption"
          onChange={(e) =>
            handleItemChange(
              e,
              item.lineItemText,
              item.class,
              item.costUnit,
              item.consumptionUnit
            )
          }
          onBlur={(e) =>
            handleBlurItem(e, item.lineItemText, item.class, item.costUnit, item.consumptionUnit)
          }
          placeholder="Ingrese consumo; Ej: 121;-344"
        />
        {" "} {item.consumptionUnit}
      </div>
    ) : (
      // Lógica estándar para los demás casos
      <div className="itemColumn">
        <input
          type="number"
          id={`consumption_${item.id}`}
          value={
            inputs?.lineItemCost.find((input) => {
              const inputText = input?.lineItemText?.toLowerCase()?.trim();
              const itemText = item?.lineItemText?.toLowerCase()?.trim();
              return inputText === itemText;
            })?.consumption ?? ""
          }
          name="consumption"
          onChange={(e) =>
            handleItemChange(
              e,
              item?.lineItemText,
              item?.class,
              item?.costUnit,
              item?.consumptionUnit
            )
          }
          placeholder="Ingrese consumo"
        />
        {" "} {item.consumptionUnit}
      </div>
    )
  ) : (
    "No aplica"
  )}
</td>


          <td>
  {item.costUnit && item.costUnit !== "null" ? (
    // Caso para Subsidio Estado Nacional o Energía reactiva con EDEN T2 B1
    (
      (item.lineItemText?.includes("Subsidio Estado Nacional") ||
        item.lineItemText?.includes("Energía reactiva")) &&
      item.proveedor?.includes("EDEN T2 B1")
    ) ||
    (
      (item.lineItemText?.includes("Cargo Fijo") ||
        item.class?.includes("Energia Consumida") ||
        item.class?.includes("Potencia Contratada")) &&
      item.proveedor?.includes("Coop._San_Pedro T2B")
    ) ||
    // Nueva validación para Coop. Trenque Lauquen T2BT con Potencia Contratada o Energia Consumida
    (
      (item.class?.includes("Potencia Contratada") ||
        item.class?.includes("Energia Consumida") || item.lineItemText?.includes("ICT- Art.5-Res. 186/19 MIySP")) &&
      item.proveedor?.includes("Coop. Trenque Lauquen T2BT")
    ) ? (
      <div className="itemColumn">
        <input
          type="text"
          id={`cost_${item.id}`}
          value={
            (displayValues?.[`${item?.lineItemText}_cost`] ||
              (inputs?.lineItemCost?.find((input) => {
                const inputText = input?.lineItemText?.toLowerCase()?.trim();
                const itemText = item?.lineItemText?.toLowerCase()?.trim();
                return inputText === itemText;
              })?.cost)) ?? ""
          }
          name="cost"
          onChange={(e) =>
            handleItemChange(
              e,
              item.lineItemText,
              item.class,
              item.costUnit,
              item.consumptionUnit
            )
          }
          onBlur={(e) =>
            handleBlurItem(
              e,
              item.lineItemText,
              item.class,
              item.costUnit,
              item.consumptionUnit
            )
          }
          placeholder="Ingrese costo; Ej: 121;-344"
        />
        {" "} {item.costUnit}$
      </div>
    ) : (
      // Input estándar para los demás casos
      <div className="itemColumn">
        <input
          type="number"
          id={`cost_${item.id}`}
          value={
            inputs?.lineItemCost.find((input) => {
              const inputText = input?.lineItemText?.toLowerCase()?.trim();
              const itemText = item?.lineItemText?.toLowerCase()?.trim();
              return inputText === itemText;
            })?.cost ?? ""
          }
          name="cost"
          onChange={(e) =>
            handleItemChange(
              e,
              item?.lineItemText,
              item?.class,
              item?.costUnit,
              item?.consumptionUnit
            )
          }
          placeholder="Ingrese costo"
        />
        {" "} {item.costUnit}$
      </div>
    )
  ) : (
    "No aplica"
  )}
</td>

            </tr>
          ))}
             {showCharge ? (
              <>
                <tr className="itemText-row">
                  <td className="">Otros recargos - Otros</td>
                  <td>
                    <div className="itemColumn"></div>
                  </td>
                  <td>
                    <div className="itemColumn">
                    <input
                        type="text"
                        id="cost"
                        name="cost"
                        value={
                          (() => {
                            const foundItem = inputs?.lineItemCost.find(
                              (input) => input.lineItemText === "Otros recargos" && input.class === "Otros"
                            );
                            return foundItem?.total ?? foundItem?.cost ?? "";
                          })()
                        }
                        onChange={(e) => handleOtherCharge(e, "Otros recargos", "Otros", "AR")}
                        placeholder="Ingrese costo, solo valor númerico. Ejemplo 9500,20"
                    />

                      AR$
                    </div>
                  </td>
                </tr>
                <tr className="itemText-row">
                  <td className="">Intereses por mora - Intereses</td>
                  <td>
                    <div className="itemColumn"></div>
                  </td>
                  <td>
                    <div className="itemColumn">
                      <input
                        type="text"
                        id="cost"
                        name="cost"
                        value={
                          (() => {
                            const foundItem = inputs?.lineItemCost.find(
                              (input) => input.lineItemText === "Intereses por mora" && input.class === "Intereses"
                            );
                            return foundItem?.total ?? foundItem?.cost ?? "";
                          })()
                        }
                        onChange={(e) =>
                          handleOtherCharge(
                            e,
                            "Intereses por mora",
                            "Intereses",
                            "AR"
                          )
                        }
                        placeholder="Ingrese costo, solo valor númerico. Ejemplo 9500,20"
                      />
                      AR$
                    </div>
                  </td>
                </tr>
                <tr className="itemText-row">
                <td className="">Otros impuestos y tasas - Impuestos</td>
                <td>
                  <div className="itemColumn"></div>
                </td>
                <td>
                  <div className="itemColumn">
                    <input
                      type="text"
                      id="cost"
                      name="cost"
                      value={
                        (() => {
                          const foundItem = inputs?.lineItemCost.find(
                            (input) => input.lineItemText === "Otros impuestos y tasas" && input.class === "Impuestos"
                          );
                          return foundItem?.total ?? foundItem?.cost ?? "";
                        })()
                      }
                      onChange={(e) =>
                        handleOtherCharge(e, "Otros impuestos y tasas", "Impuestos", "AR")
                      }
                      placeholder="Ingrese costo, solo valor númerico. Ejemplo 9500,20"
                    />
                    AR$
                  </div>
                </td>
              </tr>
                {errors.otherCharges && (
                  <span className="error-message">{errors.otherCharges}</span>
                )}
              </>
            ) : null}
          </tbody>
        </table>
      </div>
      {!showCharge ? (
        <button
          className="btn-charges"
          type="button"
          onClick={() => setShowCharge(!showCharge)}
        >
          + Agregar recargos
        </button>
      ) : null}
      <div className="info-price">
        <label>EL TOTAL DE LA FACTURA ES: </label>
        <p>$ {totalCost?.toFixed(2)}</p>
      </div>
    </>
  );
};

export default LineItemText;